import React, { useEffect, useState, memo } from "react";

import Card from "../components/Card";

const styles = {
  transformOrigin: "top left",
  transform: "rotate(-7deg) translateZ(0)",
  WebkitTransform: "rotate(-7deg)",
  willChange: "transform",
  opacity: 0.5,
};

export const CardDragPreview = memo(({ icon, title }) => {
  const [tickTock, setTickTock] = useState(false);
  useEffect(
    function subscribeToIntervalTick() {
      const interval = setInterval(() => setTickTock(!tickTock), 500);
      return () => clearInterval(interval);
    },
    [tickTock]
  );
  return (
    <div style={styles}>
      <div className="Card-stacked Card-top">
        <Card icon={icon} title={title} />
      </div>
    </div>
  );
});
