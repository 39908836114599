import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTable,
  faChartBar,
  faBars,
  faHome,
  faUser,
  faUsers,
  faHospitalUser,
  faInfoCircle,
  faQuestionCircle,
  faCog,
  faTimes,
  faPencilAlt,
  faCaretLeft,
  faUndo,
  faSignOutAlt,
  faLongArrowAltUp,
  faSpinner,
  faArrowLeft,
  faPlus,
  faCalendar,
  faFilePdf,
  faRedo,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

export default function registerIcons() {
  library.add(faTable);
  library.add(faChartBar);
  library.add(faBars);
  library.add(faHome);
  library.add(faUser);
  library.add(faUsers);
  library.add(faHospitalUser);
  library.add(faQuestionCircle);
  library.add(faInfoCircle);
  library.add(faCog);
  library.add(faTimes);
  library.add(faPencilAlt);
  library.add(faCaretLeft);
  library.add(faUndo);
  library.add(faSignOutAlt);
  library.add(faLongArrowAltUp);
  library.add(faSpinner);
  library.add(faArrowLeft);
  library.add(faPlus);
  library.add(faCalendar);
  library.add(faFilePdf);
  library.add(faRedo);
  library.add(faCheckCircle);
}
