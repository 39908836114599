import React, { useEffect, useMemo, useState } from "react";
import Header from "../components/Header";
import {
  Button,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "@react-keycloak/web";
import { Backend } from "../services/backend";

import { Link as RouterLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./User.css";
import { KEYCLOAK_ADMIN_ROLE } from "../context/UserContext";
import MyFormGroup from "../components/MyFormGroup";
import { useTools } from "../context/ToolsContext";
import { PASSWORD_MIN_LENGTH } from "../constants";

const EMPTY_USER = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
};

export default function User({ create }) {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const { userID } = useParams();
  const history = useHistory();

  const { tools, refreshTools } = useTools();

  const USER_FORM_SCHEMA = useMemo(() => {
    let shape = {
      firstName: Yup.string().required(t("required")),
      lastName: Yup.string().required(t("required")),
      email: Yup.string().email(t("invalidEmail")).required(t("required")),
    };

    if (create)
      shape.password = Yup.string()
        .min(PASSWORD_MIN_LENGTH, ({ min }) =>
          t("tooShort", { minLength: min })
        )
        .required(t("required"));

    return Yup.object().shape(shape);
  }, [create, t]);

  const [user, setUser] = useState(null);
  const [userRoles, setUserRoles] = useState(null);
  const [isUserSuperAdmin, setIsUserSuperAdmin] = useState(false);
  const [userPermissions, setUserPermissions] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const toggleDeleteModal = () => {
    setIsDeleteModalOpen((o) => !o);
  };

  const handleSuperAdminClick = (e) => {
    setIsUserSuperAdmin(e.target.checked);
  };

  const handleSubmit = async (values, { setSubmitting, setTouched }) => {
    let userToSet;

    if (create) {
      let createdUser = await Backend.createUser(keycloak.token, {
        ...values,
        isSuperAdmin: isUserSuperAdmin,
      });

      userToSet = createdUser;
    } else {
      // TODO - Support create VS update user
      let updatedUser = await Backend.updateUser(keycloak.token, userID, {
        ...values,
        isSuperAdmin: isUserSuperAdmin,
      });

      userToSet = updatedUser;
    }

    let userRoles = await Backend.getUserRoles(keycloak.token, userToSet.id);

    // Save permissions
    let allowedToolIDs = userPermissions
      .filter((t) => t.selected)
      .map((t) => t.id);

    await Backend.savePermissions(keycloak.token, userToSet.id, allowedToolIDs);

    // Update the tools to reflect the new permissions in the home screen
    await refreshTools();

    // After saving the user, reset the touched state
    setTouched({});

    setIsUserSuperAdmin(userRoles.includes(KEYCLOAK_ADMIN_ROLE));
    setUserRoles(userRoles);
    setUser(userToSet);

    setSubmitting(false);

    // Redirect to page with created user
    if (create) history.push(`/utilisateurs/${userToSet.id}`);
  };

  const handleDeleteClick = async () => {
    setIsDeleting(true);

    await Backend.deleteUser(keycloak.token, userID);

    setIsDeleting(false);

    history.push("/utilisateurs");
  };

  const togglePermissionsSwitch = (toolID) => {
    let updatedPermissions = [...userPermissions];
    let permissionToToggleIndex = userPermissions.findIndex(
      (t) => t.id === toolID
    );

    updatedPermissions[permissionToToggleIndex].selected = !updatedPermissions[
      permissionToToggleIndex
    ].selected;

    setUserPermissions(updatedPermissions);
  };

  useEffect(() => {
    async function fetchUserAndRoles() {
      let roles = await Backend.getUserRoles(keycloak.token, userID);
      let user = await Backend.getUsers(keycloak.token, userID);

      setIsUserSuperAdmin(roles.includes(KEYCLOAK_ADMIN_ROLE));
      setUserRoles(roles);
      setUserPermissions(
        tools.map((tool) => ({
          id: tool.id,
          selected: user.tools.map((t) => t.id).includes(tool.id),
        }))
      );
      setUser(user);
    }

    if (tools) {
      if (userID) {
        fetchUserAndRoles();
      } else if (create) {
        setUser({ ...EMPTY_USER });
        setUserPermissions(
          tools.map((tool) => ({
            id: tool.id,
            selected: false,
          }))
        );
      }
    }
  }, [tools, userID, keycloak, create]);

  return (
    <>
      {user && (userRoles || create) && (
        <>
          <div className="Nav-header">
            <Header />
          </div>
          <div className="Grow-flex-top">
            <Button tag={RouterLink} to="/utilisateurs" color="link">
              <FontAwesomeIcon icon="arrow-left" /> {t("backToUsers")}
            </Button>
            <h1>{!create ? t("user") : t("newUser")}</h1>
            <Formik
              initialValues={user}
              validationSchema={USER_FORM_SCHEMA}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, values, handleSubmit, errors, touched }) => (
                <>
                  <Form
                    className="User-form"
                    autoComplete="off"
                    noValidate
                    onSubmit={handleSubmit}
                  >
                    <MyFormGroup fieldName="firstName" />
                    <MyFormGroup fieldName="lastName" />
                    <MyFormGroup fieldName="email" type="email" />
                    {create && (
                      <MyFormGroup
                        fieldName="password"
                        type="password"
                        autoComplete="new-password"
                        text={t("passwordExplanation")}
                      />
                    )}
                    <FormGroup check style={{ paddingLeft: 0 }}>
                      <h5>{t("userRoles")}</h5>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={isUserSuperAdmin}
                          onChange={handleSuperAdminClick}
                        />{" "}
                        {t("superAdminRole")}
                      </Label>
                    </FormGroup>
                    <FormGroup>
                      <h5 className="mt-3">{t("userPermissions")}</h5>

                      <div className="d-flex">
                        {tools.map((tool) => (
                          <CustomInput
                            key={tool.id}
                            type="switch"
                            id={tool.slug}
                            name={tool.slug}
                            label={tool.name}
                            className="m-3"
                            checked={
                              userPermissions.find((t) => t.id === tool.id)
                                .selected
                            }
                            onChange={() => togglePermissionsSwitch(tool.id)}
                          />
                        ))}
                      </div>
                    </FormGroup>
                    <Button
                      className="mt-3"
                      color="success"
                      disabled={isSubmitting || isDeleting}
                      type="submit"
                    >
                      {!isSubmitting ? t("save") : t("saving")}
                    </Button>
                  </Form>
                  {!create && !userRoles.includes(KEYCLOAK_ADMIN_ROLE) && (
                    <div>
                      <h3 className="mt-5">{t("deleteUser")}</h3>
                      <Button
                        color="danger"
                        disabled={isSubmitting || isDeleting}
                        onClick={toggleDeleteModal}
                      >
                        {!isDeleting ? t("deleteUser") : t("deletingUser")}
                      </Button>
                    </div>
                  )}
                </>
              )}
            </Formik>
          </div>
          <Modal isOpen={isDeleteModalOpen} toggle={toggleDeleteModal} centered>
            <ModalHeader toggle={toggleDeleteModal}>
              {t("deleteUser")}
            </ModalHeader>
            <ModalBody>
              {t("deleteUserConfirmationMessage", {
                user: `${user.firstName} ${user.lastName} (${user.email})`,
              })}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleDeleteModal}>
                {t("cancel")}
              </Button>
              <Button color="danger" onClick={handleDeleteClick}>
                {t("delete")}
              </Button>{" "}
            </ModalFooter>
          </Modal>
        </>
      )}
    </>
  );
}
