import React from "react";
import Header from "../components/Header";
import PatientList from "../components/PatientList";
import { useTranslation } from "react-i18next";

export default function Patients() {
  const { t } = useTranslation();

  return (
    <>
      <div className="Nav-header">
        <Header />
      </div>
      <div className="Grow-flex-top">
        <h1>{t("patients")}</h1>
        <PatientList />
      </div>
    </>
  );
}
