import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useHistory } from "react-router-dom";

import Header from "../components/Header";

import "./Home.css";
import PatientList from "../components/PatientList";
import ApplicationCard from "../components/ApplicationCard";
import { useKeycloak } from "@react-keycloak/web";
import { useTools } from "../context/ToolsContext";

function Home() {
  const { t } = useTranslation();

  const { keycloak } = useKeycloak();

  const { tools } = useTools();

  const history = useHistory();

  const [currentTool, setCurrentTool] = useState(null);

  const [isPatientSelectionOpen, setIsPatientSelectionOpen] = useState(false);

  const togglePatientSelection = () => setIsPatientSelectionOpen((o) => !o);

  const onStartClick = (toolId) => {
    setCurrentTool(tools.find((t) => t.id === toolId));
    togglePatientSelection();
  };

  const handlePatientClick = (e, patient) => {
    let path = `/questionnaire/${currentTool.slug}/${patient.id}`;

    history.push(path);
  };

  return tools ? (
    <>
      <div className="Nav-header">
        <Header />
      </div>
      <div className="d-flex flex-column flex-md-row  justify-content-around m-3">
        {tools.map(
          ({
            id,
            name,
            subtitle,
            description,
            descriptionLong,
            image,
            users: toolUsers,
          }) => (
            <ApplicationCard
              key={id}
              id={id}
              name={name}
              subtitle={subtitle}
              description={description}
              descriptionLong={descriptionLong}
              logo={`https://via.placeholder.com/500x150?text=${name}`}
              onButtonClick={() => onStartClick(id)}
              buttonTitle={t("start")}
              // Disable if the user is not among the tool's allowed users
              disabled={
                !toolUsers.find(
                  (u) => u.keycloakId === keycloak.tokenParsed.sub
                )
              }
            />
          )
        )}
      </div>
      <Modal
        centered
        isOpen={isPatientSelectionOpen}
        toggle={togglePatientSelection}
      >
        <ModalHeader toggle={togglePatientSelection}>
          {t("selectPatient")}
        </ModalHeader>
        <ModalBody>
          <PatientList
            handlePatientClick={handlePatientClick}
            showProgress={true}
            tool={currentTool}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={togglePatientSelection}>
            {t("cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  ) : (
    <p>{t("loading")}</p>
  );
}

export default Home;
