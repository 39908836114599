import React, { useCallback, useEffect, useMemo, useState } from "react";

import PlayArea from "../components/PlayArea";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Navbar,
  NavLink,
  Nav,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomDragLayer } from "../dnd/CustomDragLayer";
import { useTranslation } from "react-i18next";
import Question from "../components/Question";
import {
  NavLink as RouterNavLink,
  Redirect,
  useParams,
} from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

import "./Questionnaire.css";
import { useKeycloak } from "@react-keycloak/web";
import { Backend } from "../services/backend";
import {
  filterCards,
  getCurrentStep,
  nextUnansweredQuestion,
  parseQuestionnaire,
} from "../utils/redcap";
import { useUser } from "../context/UserContext";

function Questionnaire() {
  const { keycloak } = useKeycloak();

  // Get tool slug & patient ID from the URL
  const { tool: toolSlug, patient: patientID } = useParams();

  // Get user for saving the creator ID in the record
  const { user } = useUser();

  const { t } = useTranslation();

  // Record ID for the current patient
  const [record, setRecord] = useState(null);

  // Initialization status
  const [initialized, setInitialized] = useState(false);

  // Questionnaire structure
  const [questionnaire, setQuestionnaire] = useState(null);

  // Questionnaire instruments
  const [instruments, setInstruments] = useState(null);

  // Current answers
  const [answers, setAnswers] = useState(null);

  // Step explanation modal
  const [explanationModalIsOpen, setExplanationModalIsOpen] = useState(false);
  const [stepExplanation, setStepExplanation] = useState(null);

  // Current step in the questionnaire
  const [currentStep, setCurrentStep] = useState(-1);

  // Load quiz from the backend
  useEffect(() => {
    async function fetchQuestionnaire() {
      // Find the questionnaire metadata based on the URL slug
      const metadata = await Backend.fetchMetadata(keycloak.token, toolSlug);

      // Parse & set the questionnaire
      let questionnaire = parseQuestionnaire(metadata);

      setQuestionnaire(questionnaire);

      // Find the questionnaire instruments based on the URL slug
      const instruments = await Backend.fetchInstruments(
        keycloak.token,
        toolSlug
      );

      // Set the instruments
      setInstruments(instruments);
    }

    if (toolSlug) fetchQuestionnaire();
  }, [keycloak.token, toolSlug]);

  // Load/Create record from the backend
  useEffect(() => {
    async function fetchCurrentRecord() {
      let currentRecord = await Backend.getCurrentRecord(
        keycloak.token,
        toolSlug,
        patientID,
        user.id
      );

      setRecord(currentRecord);
    }

    if (user && patientID && toolSlug) fetchCurrentRecord();
  }, [keycloak.token, patientID, toolSlug, user]);

  // Load current answers from the backend
  useEffect(() => {
    async function fetchAnswers() {
      // Get answers from the backend and set state
      let answers = await Backend.getAnswers(keycloak.token, record.id);
      setAnswers(answers);

      // Parse state based on the available answers
      let currentStep = getCurrentStep(questionnaire, answers);
      setCurrentStep(currentStep);

      // Set the questionnaire to initialized
      setInitialized(true);
    }

    if (toolSlug && questionnaire && record) {
      fetchAnswers();
    }
  }, [keycloak.token, toolSlug, questionnaire, record]);

  // Show explanation after initial question & on step change
  useEffect(() => {
    if (
      questionnaire &&
      currentStep >= 0 &&
      currentStep < questionnaire.steps.length
    ) {
      setStepExplanation(questionnaire.steps[currentStep].description);
      toggleExplanation();
    }
  }, [currentStep, questionnaire]);

  // Toggle explanation
  const toggleExplanation = () => {
    setExplanationModalIsOpen((o) => !o);
  };

  // Move to the next step
  const goToNextStep = () => {
    if (currentStep < questionnaire.steps.length) {
      setCurrentStep((s) => s + 1);
    }
  };

  // Finalize questionnaire
  const finalizeQuestionnaire = async () => {
    await Backend.finalizeQuestionnaire(keycloak.token, record.id);
  };

  // Generate title to display
  const getTitle = () => {
    if (currentStep < 0) return t("initialQuestion");

    if (currentStep < questionnaire.steps.length) {
      // Do we have multiple "categories" like in AERES, or just one like in ELADEB?
      let stepCategory =
        instruments.length > 1 &&
        instruments.find(
          (i) => i.instrument_name === questionnaire.steps[currentStep].category
        ).instrument_label;

      let categoryIndex = instruments.findIndex(
        (i) => i.instrument_name === questionnaire.steps[currentStep].category
      );

      let categorySteps = questionnaire.steps.filter(
        (s) => s.category === questionnaire.steps[currentStep].category
      );

      let stepNumber = stepCategory
        ? `${t("step")} ${
            categorySteps.findIndex(
              (s) => s.title === questionnaire.steps[currentStep].title
            ) + 1
          }/${categorySteps.length}`
        : `${t("step")} ${currentStep + 1}/${questionnaire.steps.length}`;

      let stepTitle = questionnaire.steps[currentStep].title
        ? `${questionnaire.steps[currentStep].title} `
        : " ";

      let stepTitleElement = (
        <>
          <span>
            {stepNumber} - {stepTitle}
          </span>
          <FontAwesomeIcon
            icon="info-circle"
            className="Step-info"
            onClick={toggleExplanation}
            title={t("stepDescription")}
          />
        </>
      );

      return (
        <>
          {
            /* Multiple categories - show the category & step, otherwise just the step */
            stepCategory ? (
              <>
                <span>
                  {t("category")} {categoryIndex + 1}/{instruments.length} -{" "}
                  {stepCategory}
                </span>
                <br />
                <small>{stepTitleElement}</small>
              </>
            ) : (
              <>{stepTitleElement}</>
            )
          }
        </>
      );
    }

    if (currentStep === questionnaire.steps.length && nextQuestion !== null)
      return t("finalQuestion");

    return t("results");
  };

  // TODO - Maybe this will be handled on the server?
  // Filter cards with branching logic based on previous answers
  const filterStepCards = (cards) => {
    // If current step is 0, no filtering should take place
    if (currentStep === 0) return cards;

    // Else check if there are branching logics in the cards
    return filterCards(cards, answers);
  };

  const updateAnswer = useCallback((variable, answer) => {
    setAnswers((a) => ({ ...a, [variable]: answer }));
  }, []);

  const [nextQuestion, last] = useMemo(() => {
    if (questionnaire && questionnaire.steps && answers) {
      return nextUnansweredQuestion(questionnaire, currentStep, answers);
    }

    return [null, false];
  }, [questionnaire, currentStep, answers]);

  return (
    <>
      {questionnaire && questionnaire.steps && instruments && initialized ? (
        <>
          <div className="Go-home">
            <Navbar className="custom-navbar" light>
              <Nav className="mr-auto" navbar>
                <NavLink to="/" exact tag={RouterNavLink}>
                  <FontAwesomeIcon icon="home" title={t("home")} />
                </NavLink>
              </Nav>
            </Navbar>
          </div>
          <div>
            <div>{getTitle()}</div>
          </div>
          {currentStep < 0 && nextQuestion !== null ? (
            <Question
              question={nextQuestion}
              last={last}
              updateAnswer={updateAnswer}
              goToNextStep={goToNextStep}
              record={record}
            />
          ) : currentStep < questionnaire.steps.length ? (
            <PlayArea
              className="Play-area"
              categories={questionnaire.steps[currentStep].categories}
              cards={filterStepCards(questionnaire.steps[currentStep].cards)}
              answers={answers}
              goToNextStep={goToNextStep}
              currentStep={currentStep}
              nbSteps={questionnaire.steps.length}
              updateAnswer={updateAnswer}
              finalizeQuestionnaire={
                questionnaire.finalQuestions.length === 0
                  ? async () => {
                      await finalizeQuestionnaire();
                      goToNextStep();
                    }
                  : null
              }
              record={record}
            />
          ) : questionnaire.finalQuestions.length > 0 &&
            nextQuestion !== null ? (
            <Question
              question={nextQuestion}
              last={last}
              buttonTitle={last ? t("finish") : t("continue")}
              buttonColor="primary"
              updateAnswer={updateAnswer}
              finalizeQuestionnaire={last ? finalizeQuestionnaire : null}
              record={record}
            />
          ) : (
            <>
              {/* When the questionnaire is finished and the final question answered (if existing), redirect to results */}
              <Redirect to={`/resultats/${toolSlug}/${patientID}`} />
            </>
          )}
          <CustomDragLayer />

          <div>
            <div>{/* Some kind of footer here */}</div>
          </div>
        </>
      ) : (
        <div
          style={{ height: "100vh", lineHeight: "100vh", fontSize: "medium" }}
        >
          <FontAwesomeIcon icon="spinner" spin size="3x" title={t("loading")} />
        </div>
      )}
      <Modal
        centered
        isOpen={explanationModalIsOpen}
        toggle={toggleExplanation}
        size="lg"
      >
        <ModalHeader toggle={toggleExplanation}>{t("description")}</ModalHeader>
        <ModalBody>
          <h5>{ReactHtmlParser(stepExplanation)}</h5>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleExplanation}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Questionnaire;
