import { rawRequest, request } from "../utils/request";

const BASE_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/api/v1`;

const endpoints = {
  tools: `${BASE_ENDPOINT}/tool`,
  surveys: `${BASE_ENDPOINT}/survey`,
  records: `${BASE_ENDPOINT}/record`,
  patients: `${BASE_ENDPOINT}/patient`,
  users: `${BASE_ENDPOINT}/user`,
};

export const Backend = {
  /////////////////////////////////////////////////////////////////////////////////////////
  // Tools
  /////////////////////////////////////////////////////////////////////////////////////////
  getTools: async function (token, toolSlug) {
    return getResources(token, endpoints.tools, toolSlug);
  },

  fetchMetadata: async function (token, toolSlug) {
    return request(`${endpoints.tools}/${toolSlug}/metadata`, { token });
  },

  fetchInstruments: async function (token, toolSlug) {
    return request(`${endpoints.tools}/${toolSlug}/instruments`, { token });
  },

  /////////////////////////////////////////////////////////////////////////////////////////
  // Surveys
  /////////////////////////////////////////////////////////////////////////////////////////
  getSurvey: async function (token, patientId) {
    let url = `${endpoints.surveys}/${patientId}`;

    return request(url, { token });
  },

  createSurvey: async function (token, patientId, creatorId) {
    let body = { creator: creatorId };

    let url = `${endpoints.surveys}/${patientId}`;

    return request(url, { method: "POST", data: body, token });
  },

  /////////////////////////////////////////////////////////////////////////////////////////
  // Records
  /////////////////////////////////////////////////////////////////////////////////////////
  getPatientToolRecords: async function (
    token,
    toolSlug,
    patientId,
    finishedOnly
  ) {
    let url = `${endpoints.records}/${toolSlug}/${patientId}`;

    if (finishedOnly) url += `?finishedOnly=true`;

    return request(url, { token });
  },

  getCurrentRecord: async function (token, toolSlug, patientId, creatorId) {
    let body = {
      creator: creatorId,
    };

    return request(`${endpoints.records}/${toolSlug}/${patientId}/current`, {
      method: "POST",
      data: body,
      token,
    });
  },

  getUnfinishedToolRecords: async function (token, toolSlug) {
    return request(`${endpoints.records}/${toolSlug}/unfinished`, { token });
  },

  getAnswers: async function (token, id) {
    let answersResponse = await request(`${endpoints.records}/${id}`, {
      token,
    });

    return answersResponse;
  },

  saveAnswer: async function (token, id, variable, answer) {
    let body = {
      questionId: variable,
      answer: answer,
    };

    return request(`${endpoints.records}/${id}`, {
      method: "POST",
      data: body,
      token,
    });
  },

  finalizeQuestionnaire: async function (token, id) {
    return request(`${endpoints.records}/${id}/finalize`, {
      method: "PATCH",
      token,
    });
  },

  exportRecords: async function (token, toolSlug) {
    return rawRequest(`${endpoints.records}/${toolSlug}/export`, {
      token,
    });
  },

  /////////////////////////////////////////////////////////////////////////////////////////
  // Users
  /////////////////////////////////////////////////////////////////////////////////////////
  getUsers: async function (token, userId) {
    return getResources(token, endpoints.users, userId);
  },

  getUserBySub: async function (token, userSub) {
    return getResources(token, `${endpoints.users}/sub`, userSub);
  },

  getUserRoles: async function (token, userId) {
    let url = `${endpoints.users}/${userId}/roles`;

    let rolesResponse = await request(url, { token });

    return rolesResponse;
  },

  createUser: async function (token, payload) {
    return createResource(token, endpoints.users, payload);
  },

  updateUser: async function (token, userId, payload) {
    return updateResource(token, endpoints.users, userId, payload);
  },

  deleteUser: async function (token, userId) {
    return deleteResource(token, endpoints.users, userId);
  },

  savePermissions: async function (token, userId, allowedToolIDs) {
    return request(`${endpoints.users}/${userId}/permissions`, {
      method: "PATCH",
      data: { allowedTools: allowedToolIDs },
      token,
    });
  },

  /////////////////////////////////////////////////////////////////////////////////////////
  // Patients
  /////////////////////////////////////////////////////////////////////////////////////////
  getPatients: async function (token, patientId) {
    return getResources(token, endpoints.patients, patientId);
  },

  createPatient: async function (token, patient) {
    return createResource(token, endpoints.patients, patient);
  },

  updatePatient: async function (token, patientId, patient) {
    return updateResource(token, endpoints.patients, patientId, patient);
  },

  deletePatient: async function (token, patientId) {
    return deleteResource(token, endpoints.patients, patientId);
  },
};

// Generic REST methods
async function getResources(token, endpoint, id) {
  let url = id ? `${endpoint}/${id}` : `${endpoint}`;

  let resourcesResponse = await request(url, { token });

  return resourcesResponse;
}

async function createResource(token, endpoint, payload) {
  let url = endpoint;

  let createResponse = await request(url, {
    token,
    method: "POST",
    data: payload,
  });

  return createResponse;
}

async function updateResource(token, endpoint, id, payload) {
  let url = `${endpoint}/${id}`;

  let updateResponse = await request(url, {
    token,
    method: "PATCH",
    data: payload,
  });

  return updateResponse;
}

async function deleteResource(token, endpoint, id) {
  let url = `${endpoint}/${id}`;

  let deleteResponse = await request(url, { token, method: "DELETE" });

  return deleteResponse;
}
