import _ from "lodash";

const STEP_TYPES = {
  CARD: "c",
  DESCRIPTION: "d",
  QUESTION: "q",
  STEP: "t",
  CARD_DEFINITION: "carte",
};

export const QUESTIONNAIRE_STATUS = {
  NOT_STARTED: "0",
  IN_PROGRESS: "1",
  FINISHED: "2",
};

export const CARDS_PREFIX = "cartes";
export const COMPLETE_SUFFIX = "_complete";

export const CHECKBOX_ANSWER_SEPARATOR = "___";
export const ANSWERED_CHOICE = "ANSWERED";

const CHOICE_SEPARATOR = " | ";
const VALUE_SEPARATOR = ",";

export function getCurrentStep(questionnaire, answers) {
  // Special case -> We haven't answered the initial question yet (if there is one)
  if (nextUnansweredQuestion(questionnaire, -1, answers)[0] !== null) return -1;

  // Otherwise, figure out step based on the step variables
  for (let [index, step] of questionnaire.steps.entries()) {
    let filteredCards = filterCards(step.cards, answers);

    let stepVariables = filteredCards.map((c) => c.variable);

    let unansweredQuestion = Object.keys(answers)
      .filter((v) => stepVariables.includes(v))
      .find((v) => answers[v] === "");

    if (unansweredQuestion) return index;
  }

  // If no unanswered questions exist, return the current length of steps
  return questionnaire.steps.length;
}

export function filterCards(cards, answers) {
  if (cards[0].condition !== null) {
    return cards.filter((c) => {
      let answer = answers[c.condition.variable];
      return answer === c.condition.value;
    });
  } else {
    return cards;
  }
}

export function getMoveNumber(cards, answers) {
  let stepVariables = cards.map((c) => c.variable);

  let missingAnswers = Object.entries(answers).filter(
    ([variable, answer]) => stepVariables.includes(variable) && answer === ""
  );

  return cards.length - missingAnswers.length;
}

export function createHistory(cards, categories, answers, moveNumber) {
  // Generate move history from existing answers
  let history = [];

  // Copy of all card variables
  let cardVariables = cards.map((c) => c.variable);

  // Check which cards have been played or not yet
  let playedCards = cardVariables.filter((v) => answers[v] !== "");
  let unplayedCards = cardVariables.filter((v) => answers[v] === "");

  // Create stack (first the played cards, then the unplayed ones)
  let cardStack = [...playedCards, ...unplayedCards];

  // Empty set of categories
  let categoryEntries = {};
  for (let category of categories) {
    categoryEntries[category.value] = [];
  }

  // Add initial step to the history
  history.push({
    cardStack: [...cardStack],
    categoryCards: _.cloneDeep(categoryEntries),
  });

  // Generate history entries
  for (let i = 0; i < moveNumber; i++) {
    let historyEntry = {};

    // Remove card from the stack for next entry
    let removedCard = cardStack.shift();

    // Add card to the correct category
    categoryEntries[answers[removedCard]].push(removedCard);

    // Define card stack
    historyEntry.cardStack = [...cardStack];

    // Manage card categories
    historyEntry.categoryCards = _.cloneDeep(categoryEntries);

    // Add to history
    history.push(historyEntry);
  }

  return history;
}

export function parseQuestionnaire(metadata) {
  let parsedQuestionnaire = {
    initialQuestions: [],
    finalQuestions: [],
  };

  let steps = getSteps(metadata);
  console.log("Steps are", steps);

  // Identify the index of the first step, to split questions into pre/post sorting
  let firstStepIndex = metadata.findIndex(
    (e) => e.field_name === steps[0].field_name
  );

  let questions = getQuestions(metadata);

  let parsedQuestions = parseQuestions(questions);
  console.log("Questions are", parsedQuestions);

  // Split questions into pre/post sorting
  if (parsedQuestions.length > 0) {
    for (let parsedQuestion of parsedQuestions) {
      let questionIndex = metadata.findIndex(
        (m) => m.field_name === parsedQuestion.field_name
      );

      if (questionIndex > firstStepIndex) {
        // Seems this question is at the end
        parsedQuestionnaire.finalQuestions.push(parsedQuestion);
      } else {
        // Seems the question is at the beginning
        parsedQuestionnaire.initialQuestions.push(parsedQuestion);
      }
    }
  }

  let cards = getCards(metadata);
  console.log("Cards are", cards);

  let parsedSteps = parseSteps(steps, cards);
  console.log("Parsed steps are", Array.from(parsedSteps.values()));
  parsedQuestionnaire.steps = Array.from(parsedSteps.values());

  return parsedQuestionnaire;
}

function parseQuestions(questions) {
  let parsedQuestions = [];

  for (let question of questions) {
    // Parse based on the type of question (free-text or checkbox)
    let { field_name, field_label } = question;

    switch (question.field_type) {
      case "text":
        parsedQuestions.push({ field_name, field_label });
        break;
      case "checkbox":
        // Parse possible answers
        let choiceEntries = question.select_choices_or_calculations.split(
          CHOICE_SEPARATOR
        );

        // Get answer value & label for each answer
        let choices = choiceEntries.map((choice) => {
          let [value, label] = choice.split(VALUE_SEPARATOR);
          return { value: value.trim(), label: label.trim() };
        });

        parsedQuestions.push({ field_name, field_label, choices });
        break;
      default:
        console.error("This type of question is NOT supported!");
    }
  }

  return parsedQuestions;
}

function parseSteps(steps, cards) {
  return steps.reduce((acc, step) => {
    try {
      let { stepNumber, stepType, subNumber } = step.field_name.match(
        /[t](?<stepNumber>\d+)(?<stepType>[a-z])(?<subNumber>\d+)/
      ).groups;

      if (!acc.has(stepNumber)) acc.set(stepNumber, {});

      let currentStep = acc.get(stepNumber);

      // Define step "category"
      currentStep.category = step.form_name;

      switch (stepType) {
        case STEP_TYPES.DESCRIPTION:
          currentStep.title = step.section_header;
          currentStep.description = step.field_label;
          currentStep.number = stepNumber;
          break;
        case STEP_TYPES.CARD:
          // Save categories
          if (!Object.keys(currentStep).includes("categories"))
            currentStep.categories = parseCategories(
              step.select_choices_or_calculations
            );

          // Save cards
          if (!Object.keys(currentStep).includes("cards"))
            currentStep.cards = [];
          currentStep.cards.push({
            variable: step.field_name,
            title: parseTitle(step.field_label),
            icon: getImageDataURL(
              cards.find((c) => c.field_name === `carte${subNumber}`)
                .field_label
            ),
            condition: parseCondition(step.branching_logic),
          });
          break;
        default:
          console.error(`The step type ${stepType} is not supported!!!`);
      }
    } catch (e) {
      console.error(step.field_name);
      console.error(e);
      return acc;
    }

    return acc;
  }, new Map());
}

function getImageDataURL(data) {
  if (!isValidHttpUrl(data)) return `data:image/jpeg;base64,${data}`;
  return "";
}

function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

function parseCondition(branchingLogicText) {
  if (!branchingLogicText) return null;

  let { variable, value } = branchingLogicText.match(
    /\[(?<variable>.*)\] = '(?<value>.*)'/
  ).groups;

  return { variable: variable, value: value };
}

function parseTitle(titleText) {
  return titleText;
}

function parseCategories(choicesText) {
  let choices = choicesText.split(CHOICE_SEPARATOR);

  return choices.map((c) => {
    let [value, name] = c.split(VALUE_SEPARATOR);
    return { value: value, name: name.trim() };
  });
}

function getCards(metadata) {
  return getFieldsByPrefix(STEP_TYPES.CARD_DEFINITION, metadata);
}

function getSteps(metadata) {
  return getFieldsByPrefix(STEP_TYPES.STEP, metadata);
}

function getQuestions(metadata) {
  return getFieldsByPrefix(STEP_TYPES.QUESTION, metadata);
}

function getFieldsByPrefix(prefix, metadata) {
  let matchingFields = metadata.filter((f) => f.field_name.startsWith(prefix));

  return matchingFields;
}

export function nextUnansweredQuestion(questionnaire, currentStep, answers) {
  let questionsToInspect =
    currentStep < 0
      ? questionnaire.initialQuestions
      : questionnaire.finalQuestions;

  let [unansweredQuestion, last] = getFirstUnansweredQuestion(
    questionsToInspect,
    answers
  );

  return [unansweredQuestion, last];
}

function getFirstUnansweredQuestion(questions, answers) {
  let i = 0;

  for (let question of questions) {
    // Find answer based on question type (text or checkbox)
    if (question.choices) {
      if (
        +answers[
          `${
            question.field_name
          }${CHECKBOX_ANSWER_SEPARATOR}${ANSWERED_CHOICE.toLowerCase()}`
        ] === 0
      )
        return [question, i === questions.length - 1];
    } else {
      if (!answers[question.field_name])
        return [question, i === questions.length - 1];
    }

    i++;
  }

  return [null, false];
}
