import React, { useEffect, useRef } from "react";

import "./Category.css";
import folder from "../assets/folder.png";
import folderActive from "../assets/folder-active.png";
import { useDrop } from "react-dnd";
import { ItemTypes } from "../constants";

import useResizeObserver from "@react-hook/resize-observer";

const useSize = (target) => {
  const [size, setSize] = React.useState();

  React.useLayoutEffect(() => {
    setSize(target.current.getBoundingClientRect());
  }, [target]);

  // Where the magic happens
  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
};

export default function Category({
  name,
  value,
  cards,
  moveCard,
  handleCategoryClick,
}) {
  const [{ canDrop, isOver }, dropRef] = useDrop({
    accept: ItemTypes.CARD,
    canDrop: (props, monitor) => {
      // If we're coming from the stack, we can always drop
      if (!props.fromFolder) return true;

      // If we're coming from a folder, check that we don't want to drop on the same one
      return !cards.map((c) => c.variable).includes(props.variable);
    },
    drop: async (item, monitor) => await moveCard(item, value),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  // Reference to the folder image, used for sizing the text
  const imageRef = useRef();

  // Size hook using the "ResizeObserver" API to detect changes in size
  const imageSize = useSize(imageRef);

  // Preload images
  useEffect(() => {
    const imageList = [folder, folderActive];
    imageList.forEach((image) => {
      new Image().src = image;
    });
  }, []);

  return (
    <div ref={dropRef} className="Category">
      <div
        className="Category-folder"
        onClick={() => handleCategoryClick(name)}
      >
        <img
          alt={name}
          className={"Category-folder-image"}
          src={isOver && canDrop ? folderActive : folder}
          ref={imageRef}
        />
      </div>
      <span
        className="Category-name"
        style={{
          color: isOver && canDrop ? "white" : null,
          width: imageSize && imageSize.width,
        }}
      >
        {name} {cards && `(${cards.length})`}
      </span>
    </div>
  );
}
