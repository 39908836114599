import React, { useMemo } from "react";
import Header from "../components/Header";
import { Button, Col, Form, Row } from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "@react-keycloak/web";
import { Backend } from "../services/backend";

import "./User.css";
import { useUser } from "../context/UserContext";
import MyFormGroup from "../components/MyFormGroup";
import { PASSWORD_MIN_LENGTH } from "../constants";
import MySelectGroup from "../components/MySelectGroup";

export default function Profile() {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();

  const { user, setUser } = useUser();

  const PROFILE_FORM_SCHEMA = useMemo(() => {
    let shape = {
      firstName: Yup.string().required(t("required")),
      lastName: Yup.string().required(t("required")),
      email: Yup.string().email(t("invalidEmail")).required(t("required")),
      yearOfBirth: Yup.number()
        .min(1900, ({ min }) => t("tooLow", { min: min }))
        .max(new Date().getFullYear(), ({ max }) => t("tooHigh", { max: max }))
        .nullable(),
      country: Yup.string().nullable(),
      region: Yup.string().nullable(),
      specialty: Yup.string().nullable(),
      password: Yup.string().min(PASSWORD_MIN_LENGTH, ({ min }) =>
        t("tooShort", { minLength: min })
      ),
      passwordRepeat: Yup.string().test(
        "passwordsMustMatch",
        t("passwordsMustMatch"),
        function (value) {
          return this.parent.password === value;
        }
      ),
    };

    return Yup.object().shape(shape);
  }, [t]);

  const handleSubmit = async (values, { setSubmitting, setTouched }) => {
    let updatedUser = await Backend.updateUser(keycloak.token, user.id, {
      ...values,
    });

    // After saving the user, reset the touched state
    setTouched({});

    setUser(updatedUser);

    setSubmitting(false);
  };

  return (
    <>
      {user && (
        <>
          <div className="Nav-header">
            <Header />
          </div>
          <div className="Grow-flex-top">
            <h1>{t("myProfile")}</h1>
            <Formik
              initialValues={{ ...user, password: "", passwordRepeat: "" }}
              validationSchema={PROFILE_FORM_SCHEMA}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, values, handleSubmit, errors, touched }) => (
                <>
                  <Form
                    className="User-form"
                    autoComplete="off"
                    noValidate
                    onSubmit={handleSubmit}
                  >
                    <Row>
                      <Col>
                        <MyFormGroup fieldName="firstName" disabled />
                      </Col>
                      <Col>
                        <MyFormGroup fieldName="lastName" disabled />
                      </Col>
                    </Row>

                    <MyFormGroup fieldName="email" type="email" disabled />

                    <MyFormGroup fieldName="yearOfBirth" type="number" />

                    <MySelectGroup
                      fieldName="country"
                      options={t("countries", { returnObjects: true })}
                    />

                    <MyFormGroup fieldName="region" />

                    <MyFormGroup
                      fieldName="specialty"
                      placeholder={t("specialtyPlaceholder")}
                    />

                    <h4>{t("changePassword")}</h4>

                    <MyFormGroup
                      fieldName="password"
                      type="password"
                      autoComplete="new-password"
                      label={t("leaveEmptyToNotChange")}
                    />

                    <MyFormGroup
                      fieldName="passwordRepeat"
                      type="password"
                      autoComplete="new-password"
                      label={false}
                    />

                    <Button
                      className="mt-3"
                      color="success"
                      disabled={isSubmitting}
                      type="submit"
                    >
                      {!isSubmitting ? t("save") : t("saving")}
                    </Button>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </>
      )}
    </>
  );
}
