import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Backend } from "../services/backend";
import { useKeycloak } from "@react-keycloak/web";
import { QUESTIONNAIRE_STATUS } from "../utils/redcap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useWindowFocus from "use-window-focus";
import { useUser } from "../context/UserContext";

export default function PatientModal({
  isOpen,
  isEdit,
  toggle,
  handleSaveClick,
  patient,
  handleChange,
  title,
}) {
  const { t } = useTranslation();

  const { user } = useUser();

  const windowFocused = useWindowFocus();

  const { keycloak } = useKeycloak();

  const [isLoading, setIsLoading] = useState(true);

  const [sociodemographicQuestionnaire, setSociodemographicQuestionnaire] =
    useState(null);

  useEffect(() => {
    async function fetchSurveyLink() {
      setIsLoading(true);

      let sociodemographicResponse = await Backend.getSurvey(
        keycloak.token,
        patient.id
      );

      if (!sociodemographicResponse) {
        sociodemographicResponse = await Backend.createSurvey(
          keycloak.token,
          patient.id,
          user.id
        );
      }

      setSociodemographicQuestionnaire(sociodemographicResponse);
      setIsLoading(false);
    }

    if (isEdit && isOpen && patient.id && user.id && windowFocused)
      fetchSurveyLink();
  }, [isEdit, isOpen, patient, user, keycloak.token, windowFocused]);

  const submit = (e) => {
    handleSaveClick(e);
  };

  const getLinkButton = () => {
    if (isLoading)
      return (
        <div>
          <FontAwesomeIcon icon="spinner" spin />
        </div>
      );
    if (!sociodemographicQuestionnaire) return null;

    let sociodemographicCompleted =
      sociodemographicQuestionnaire.status === QUESTIONNAIRE_STATUS.FINISHED;

    return (
      <div>
        <span
          className={
            !sociodemographicCompleted ? "text-danger" : "text-success"
          }
        >
          {!sociodemographicCompleted ? t("missing") : t("existing")}
        </span>
        {!sociodemographicCompleted && (
          <Button
            tag="a"
            target="_blank"
            href={sociodemographicQuestionnaire.url}
            color={!sociodemographicCompleted ? "success" : "info"}
            className="mt-2"
            onClick={() => console.log("click!")}
          >
            {t("openSurvey")}
          </Button>
        )}
      </div>
    );
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Form onSubmit={submit}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          {/* Pseudonym */}
          <FormGroup>
            <Label for="patientName">
              <strong>*{t("namePseudo")}</strong>
            </Label>
            <Input
              type="text"
              name="pseudonym"
              id="patientName"
              placeholder={t("namePseudo")}
              value={patient.pseudonym}
              onChange={handleChange}
            />
          </FormGroup>
          {isEdit && (
            <FormGroup>
              <Label>
                <strong>{t("sociodemographic")}</strong>
              </Label>
              {getLinkButton()}
            </FormGroup>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            {t("cancel")}
          </Button>
          <Button color="primary" disabled={!patient.pseudonym} type="submit">
            {!isEdit ? t("add") : t("save")}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
