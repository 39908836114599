import React, { useCallback, useContext, useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { Backend } from "../services/backend";

export const ToolsContext = React.createContext();
export const useTools = () => useContext(ToolsContext);

export default function ToolsProvider({ children }) {
  const { initialized, keycloak } = useKeycloak();

  const [tools, setTools] = useState(null);

  const refreshTools = useCallback(async () => {
    let tools = await Backend.getTools(keycloak.token);

    setTools(tools);
  }, [keycloak.token]);

  useEffect(() => {
    async function fetchTools() {
      let tools = await Backend.getTools(keycloak.token);

      setTools(tools);
    }

    if (initialized) fetchTools();
  }, [initialized, keycloak.token]);

  const toolsContext = React.useMemo(
    () => ({
      tools,
      setTools,
      refreshTools,
    }),
    [tools, setTools, refreshTools]
  );

  return (
    <ToolsContext.Provider value={toolsContext}>
      {children}
    </ToolsContext.Provider>
  );
}
