import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useKeycloak } from "@react-keycloak/web";
import { Backend } from "../services/backend";
import { useTranslation } from "react-i18next";
import { Button, ListGroup, ListGroupItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link as RouterLink } from "react-router-dom";

import "./Users.css";

function User({ id, firstName, lastName }) {
  return (
    <div className="User-item position-relative align-items-center">
      <Button
        tag={RouterLink}
        to={`/utilisateurs/${id}`}
        color="link"
        className="p-0 flex-grow-1"
        title={`${firstName} ${lastName} (${id})`}
      >
        <div className="d-flex">
          <div className="User-icon">
            <FontAwesomeIcon icon="user" />
          </div>
          <div>
            {firstName} {lastName}
          </div>
        </div>
      </Button>
    </div>
  );
}

export default function Users() {
  const { t } = useTranslation();

  const { keycloak } = useKeycloak();

  const [users, setUsers] = useState(null);

  useEffect(() => {
    async function fetchUsers() {
      let users = await Backend.getUsers(keycloak.token);
      console.log("users", users);
      setUsers(users);
    }

    fetchUsers();
  }, [keycloak]);

  return (
    <>
      {users && (
        <>
          <div className="Nav-header">
            <Header />
          </div>
          <div className="Grow-flex-top">
            <h1>{t("users")}</h1>
            <Button
              tag={RouterLink}
              to="/utilisateurs/nouveau"
              color="success"
              className="m-2"
            >
              <FontAwesomeIcon icon="plus" /> {t("createUser")}
            </Button>
            <ListGroup className="User-list">
              {users
                .sort((u1, u2) => u1.lastName.localeCompare(u2.lastName))
                .map((u) => (
                  <ListGroupItem key={u.id}>
                    <User
                      id={u.id}
                      firstName={u.firstName}
                      lastName={u.lastName}
                    />
                  </ListGroupItem>
                ))}
            </ListGroup>
          </div>
        </>
      )}
    </>
  );
}
