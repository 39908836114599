export default function HighchartsTranslate(H) {
  H.setOptions({
    lang: {
      months: [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ],
      shortMonths: [
        "Jan",
        "Fév",
        "Mar",
        "Avr",
        "Mai",
        "Juin",
        "Juil",
        "Août",
        "Sep",
        "Oct",
        "Nov",
        "Déc",
      ],
      weekdays: [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
      ],
      decimalPoint: ",",
      downloadCSV: "Télécharger les données au format CSV",
      downloadPNG: "Télécharger au format PNG",
      downloadJPEG: "Télécharger au foramt JPEG",
      downloadPDF: "Télécharger au format PDF",
      downloadSVG: "Télécharger au format Vectoriel",
      exportButtonTitle: "Export du graphique",
      loading: "Chargement en cours...",
      printChart: "Imprimer le graphique",
      resetZoom: "Réinitialiser le zoom",
      resetZoomTitle: "Réinitialiser le zoom au niveau 1:1",
      thousandsSep: " ",
      viewFullscreen: "Voir en plein écran",
    },
  });
}
