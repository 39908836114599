export async function request(
  url,
  { method = "GET", token = null, data = null } = {}
) {
  try {
    // Get the response using the "raw" request function
    let response = await rawRequest(url, { method, token, data });

    // If the request was not successful, throw
    if (!response.ok) {
      let body = await response.json();
      const error = body.error || body.message;
      throw new Error(error);
    } else {
      // Request was successful, return response body
      try {
        let body = await response.json();
        return body;
      } catch (e) {
        return null;
      }
    }
  } catch (e) {
    throw e;
  }
}

export async function rawRequest(
  url,
  { method = "GET", token = null, data = null } = {}
) {
  let headers = new Headers();
  let options = {
    headers: headers,
    method: method,
  };

  // Authentication
  if (token) {
    headers.append("Authorization", getTokenAuthorization(token));
  }

  // Add body
  if ((method === "POST" || method === "PATCH" || method === "PUT") && data) {
    headers.append("Content-Type", "application/json");
    options.body = JSON.stringify(data);
  }

  // Execute the request
  let response = await fetch(url, options);

  return response;
}

function getTokenAuthorization(token) {
  return `Bearer ${token}`;
}
