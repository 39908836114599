import React, { useEffect, useState } from "react";

import { Button, ListGroup, ListGroupItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./PatientList.css";
import { useTranslation } from "react-i18next";
import PatientModal from "./PatientModal";
import keycloak from "../utils/keycloak";
import { Backend } from "../services/backend";
import { Link } from "react-router-dom";

function Patient({
  name,
  deletePatient,
  toggleEditPatient,
  handlePatientClick,
  inProgress,
  readOnly,
}) {
  const { t } = useTranslation();

  return (
    <div className="Patient-item position-relative align-items-center">
      <Button
        color="link"
        disabled={handlePatientClick ? false : true}
        onClick={(e) =>
          handlePatientClick
            ? handlePatientClick(e, name)
            : () => console.log("hai")
        }
        className="p-0 flex-grow-1"
      >
        <div className="d-flex">
          <div className="Patient-icon">
            <FontAwesomeIcon icon="user" />
          </div>
          <div>{name}</div>
          {inProgress && <div className="pl-1">({t("inProgress")})</div>}
        </div>
      </Button>
      {!readOnly && (
        <div className="Patient-actions position-absolute" style={{ right: 0 }}>
          <FontAwesomeIcon
            icon="pencil-alt"
            onClick={toggleEditPatient}
            title={t("editPatient", { patient: name })}
            className="mr-3"
          />
          <FontAwesomeIcon
            icon="times"
            onClick={deletePatient}
            title={t("deletePatient", { patient: name })}
          />
        </div>
      )}
    </div>
  );
}

const EMPTY_PATIENT = {
  pseudonym: "",
};

export default function PatientList({ handlePatientClick, readOnly, tool }) {
  const [patients, setPatients] = useState([]);
  const [unfinishedRecords, setUnfinishedRecords] = useState(null);
  const [isNewPatientOpen, setIsNewPatientOpen] = useState(false);
  const [isEditPatientOpen, setIsEditPatientOpen] = useState(false);

  const [currentPatient, setCurrentPatient] = useState(EMPTY_PATIENT);

  const { t } = useTranslation();

  // Fetch Patients
  useEffect(() => {
    async function fetchPatients() {
      let patients = await Backend.getPatients(keycloak.token);
      setPatients(patients);
    }

    fetchPatients();
  }, []);

  // Fetch records for the Tool (if provided)
  useEffect(() => {
    async function fetchRecords() {
      let unfinishedRecords = await Backend.getUnfinishedToolRecords(
        keycloak.token,
        tool.slug
      );
      setUnfinishedRecords(unfinishedRecords);
    }

    if (tool) fetchRecords();
  }, [tool]);

  // Toggle modals
  const toggleNewPatient = () => {
    setCurrentPatient(EMPTY_PATIENT);
    setIsNewPatientOpen((o) => !o);
  };

  const toggleEditPatient = (e, patient) => {
    if (patient) {
      setCurrentPatient(patient);
    } else {
      setCurrentPatient(EMPTY_PATIENT);
    }
    setIsEditPatientOpen((o) => !o);
  };

  // Handle events
  const handleFieldChange = (e) => {
    let usedValue =
      e.target.value === "true" || e.target.value === "false"
        ? JSON.parse(e.target.value)
        : e.target.value;

    setCurrentPatient((p) => ({ ...p, [e.target.name]: usedValue }));
  };

  const handleNewPatientSaveClick = async (e) => {
    // Avoid page reload on form submit
    e.preventDefault();

    // Save patient in the backend
    let newPatient = await Backend.createPatient(
      keycloak.token,
      currentPatient
    );

    // Add new patient to the list
    let newPatients = [...patients, newPatient];

    // Set new patients
    setPatients(newPatients);

    // Close the "new patient" modal and open the "edit patient" one
    toggleNewPatient();
    toggleEditPatient(e, newPatient);
  };

  const handleEditPatientSaveClick = async (e) => {
    // Avoid page reload on form submit
    e.preventDefault();

    // Update patient in DB
    let updatedPatient = await Backend.updatePatient(
      keycloak.token,
      currentPatient.id,
      currentPatient
    );

    // Replace edited patient in the list
    let newPatients = [...patients];

    let patientToUpdateIndex = newPatients.findIndex(
      (p) => p.id === currentPatient.id
    );
    newPatients[patientToUpdateIndex] = updatedPatient;

    // Set new patients
    setPatients(newPatients);

    toggleEditPatient();
  };

  const handleDeletePatient = async (id) => {
    // TODO - Maybe add a modal here also, it's a bit dangerous to just delete a patient like this

    // Delete patient from DB
    await Backend.deletePatient(keycloak.token, id);

    // Filter out deleted patient from the list
    let newPatients = patients.filter((p) => p.id !== id);

    // Update patients list
    setPatients(newPatients);
  };

  // If we have selected a tool but don't have the records yet, wait
  if (tool && unfinishedRecords === null)
    return <div className="text-center">{t("loading")}</div>;

  return (
    <div className="text-center">
      {!readOnly && (
        <Button color="success" className="m-2" onClick={toggleNewPatient}>
          <FontAwesomeIcon icon="plus" /> {t("addPatient")}
        </Button>
      )}
      {patients.length > 0 ? (
        <ListGroup className="Patient-list">
          {patients
            .sort((p1, p2) =>
              p1.pseudonym.localeCompare(p2.pseudonym, undefined, {
                numeric: true,
                sensitivity: "base",
              })
            )
            .map((p, index) => (
              <ListGroupItem key={index}>
                <Patient
                  name={p.pseudonym}
                  deletePatient={() => handleDeletePatient(p.id)}
                  toggleEditPatient={(e) => toggleEditPatient(e, p)}
                  handlePatientClick={
                    handlePatientClick ? (e) => handlePatientClick(e, p) : null
                  }
                  inProgress={
                    unfinishedRecords
                      ? unfinishedRecords.filter((r) => r.patient === p.id)
                          .length > 0
                      : false
                  }
                  readOnly={readOnly}
                />
              </ListGroupItem>
            ))}
        </ListGroup>
      ) : (
        <>
          <div>{t("noPatients")}</div>
          {readOnly && (
            <div>
              <Link to="/personnes">{t("createPatient")}</Link>
            </div>
          )}
        </>
      )}
      <PatientModal
        isOpen={isNewPatientOpen}
        handleSaveClick={handleNewPatientSaveClick}
        handleChange={handleFieldChange}
        title={t("addPatient")}
        toggle={toggleNewPatient}
        patient={currentPatient}
      />
      <PatientModal
        isOpen={isEditPatientOpen}
        isEdit={true}
        handleSaveClick={handleEditPatientSaveClick}
        handleChange={handleFieldChange}
        title={t("editPatient")}
        toggle={toggleEditPatient}
        patient={currentPatient}
      />
    </div>
  );
}
