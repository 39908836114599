import React, { useMemo, useRef, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";

import HighchartsTranslate from "../localization/highcharts";

import "./Charts.css";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

// Initialize Highcharts exporting
HighchartsExporting(Highcharts);

// Translate Highcharts to French
HighchartsTranslate(Highcharts);

Highcharts.getSVG = function (charts) {
  let top = 0;
  let width = 0;

  const groups = charts
    .map((chart) => {
      let svg = chart.getSVG();
      // Get width/height of SVG for export
      const svgWidth = +svg.match(/^<svg[^>]*width\s*=\s*"?(\d+)"?[^>]*>/)[1];
      const svgHeight = +svg.match(/^<svg[^>]*height\s*=\s*"?(\d+)"?[^>]*>/)[1];

      svg = svg
        .replace("<svg", '<g transform="translate(0,' + top + ')" ')
        .replace("</svg>", "</g>");

      top += svgHeight;
      width = Math.max(width, svgWidth);

      return svg;
    })
    .join("");

  return `<svg height="${top}" width="${width}" version="1.1"
        xmlns="http://www.w3.org/2000/svg">
            ${groups}
        </svg>`;
};

/**
 * Create a global exportCharts method that takes an array of charts as an
 * argument, and exporting options as the second argument
 */
Highcharts.exportCharts = function (charts, options) {
  // Add spacing
  for (let chart of charts) {
    chart.update({ chart: { spacing: 40 } }, false);
  }

  // Merge the options
  options = Highcharts.merge(Highcharts.getOptions().exporting, options);

  // Post to export server
  Highcharts.post(options.url, {
    filename: options.filename || "chart",
    type: options.type,
    //width: options.width,
    ...options,
    svg: Highcharts.getSVG(charts),
  });

  // Reset spacing
  for (let chart of charts) {
    chart.update({ chart: { spacing: [10, 10, 15, 10] } }, false);
  }
};

const theme = {
  //colors: ["#41B5E9", "#FA8832", "#34393C", "#E46151"],
  colors: [
    "#377eb8",
    "#ff7f00",
    "#4daf4a",
    "#e41a1c",
    "#984ea3",
    "#6AF9C4",
    "#a65628",
    "#FFF263",
    "#f781bf",
  ],
  chart: {
    style: {
      color: "#333",
      fontFamily:
        "-apple-system, BlinkMacSystemFont, Roboto, Open Sans, sans-serif",
    },
  },
  title: {
    style: {
      fontFamily:
        "-apple-system, BlinkMacSystemFont, Roboto, Open Sans, sans-serif",
      fontSize: "large",
    },
  },
  subtitle: {
    style: {
      fontFamily:
        "-apple-system, BlinkMacSystemFont, Roboto, Open Sans, sans-serif",
      fontWeight: "100",
    },
  },
  xAxis: {
    gridLineWidth: 1,
    gridLineColor: "#F3F3F3",
    lineColor: "#F3F3F3",
    minorGridLineColor: "#F3F3F3",
    tickColor: "#F3F3F3",
    tickWidth: 1,
    type: "category",
  },
  yAxis: {
    gridLineColor: "#F3F3F3",
    lineColor: "#F3F3F3",
    minorGridLineColor: "#F3F3F3",
    tickColor: "#F3F3F3",
    tickWidth: 1,
  },
};

const exporting = {
  exporting: {
    allowHTML: true,
    buttons: {
      contextButton: { enabled: false },
    },
    chartOptions: {
      chart: {
        spacing: 40,
      },
    },
    scale: 1,
    sourceWidth: 1500, //1754
    sourceHeight: 1150, //1240
  },
};

Highcharts.setOptions({ ...theme, ...exporting });

export default function Charts({ data, instruments, title, filename }) {
  const { t } = useTranslation();

  const charts = data;

  const [horizontal, setHorizontal] = useState(false);

  const chartRefs = useRef([]);
  chartRefs.current = [];

  const chartsOptions = useMemo(
    () =>
      Object.keys(charts).map((instrumentName) => ({
        chart: {
          type: horizontal ? "bar" : "column",
        },
        plotOptions: {
          column: {
            stacking: "normal",
          },
        },
        xAxis: {
          categories: charts[instrumentName].categories,
          gridLineWidth: 1,
          gridLineDashStyle: "dash",
          labels: {
            rotation: horizontal ? 0 : -90,
            style: {
              //textOverflow: "none",
              whiteSpace: horizontal ? "nowrap" : null, // Don't cut off labels in 'horizontal' mode
              fontSize: "16px",
            },
          },
        },
        yAxis: {
          title: false,
          tickInterval: 1,
          maxPadding: 0,
          gridLineDashStyle: "dash",
        },
        legend: {
          symbolRadius: 0,
          itemStyle: {
            fontSize: "16px",
          },
        },
        series: charts[instrumentName].series,
        credits: { enabled: false },
        title: {
          text:
            instruments.length > 1
              ? `${title} - ${
                  instruments.find((i) => i.instrument_name === instrumentName)
                    .instrument_label
                }`
              : title,
          widthAdjust: -250,
        },
        exporting: {
          buttons: {
            contextButton: { enabled: false },
          },
        },
      })),
    [charts, instruments, title, horizontal]
  );

  // const longestLabel = _.maxBy(
  //   categories.map((v) => v.card),
  //   (t) => t.length
  // );
  //
  // const longestLabelWidth = metrics.width(longestLabel);

  const exportPDF = () => {
    Highcharts.exportCharts(chartRefs.current, {
      type: "application/pdf",
      filename: filename,
    });
  };

  const flipChart = () => {
    setHorizontal((h) => !h);
  };

  const addToRefs = (el) => {
    if (el && !chartRefs.current.includes(el.chart)) {
      chartRefs.current.push(el.chart);
      // Reflow chart on adding to resize correctly
      el.chart.reflow();
    }
  };

  return (
    <>
      <div className="chart-wrapper flex-grow-1 d-flex flex-column">
        <Button color="link" className="flip-button" onClick={flipChart}>
          <FontAwesomeIcon icon="redo" title={t("flipChart")} />{" "}
          <span className="flip-title">{t("flipChart")}</span>
        </Button>
        <Button color="link" className="export-button" onClick={exportPDF}>
          <FontAwesomeIcon icon="file-pdf" title={t("exportPDF")} />{" "}
          <span className="export-title">{t("exportPDF")}</span>
        </Button>
        {chartsOptions.map((chartOptions, i) => (
          <React.Fragment key={i}>
            <div className="chart-main-wrapper">
              <HighchartsReact
                ref={addToRefs}
                highcharts={Highcharts}
                options={chartOptions}
                containerProps={{
                  className: `chart-container flex-grow-1 ${
                    horizontal ? "chart-container-horizontal" : null
                  }`,
                }}
              />
            </div>
            {/*<HighchartsReact
            ref={chartRefs}
            highcharts={Highcharts}
            options={chartOptions}
            containerProps={{
              className: "chart-container flex-grow-1",
            }}
          />*/}
          </React.Fragment>
        ))}
      </div>
    </>
  );
}
