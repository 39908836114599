import countriesEN from "./countries.en";
import countriesFR from "./countries.fr";

export const translations = {
  en: {
    translation: {
      // Navbar
      home: "Home",
      patients: "People",
      results: "Results",
      usage: "Usage",
      users: "Users",
      logout: "Sign out",
      configuration: "Configuration",

      // Actions
      cancel: "Cancel",
      save: "Save",
      add: "Add",
      saving: "Saving...",
      delete: "Delete",
      undo: "Undo",

      // State
      loading: "Loading...",

      // Questionnaire
      start: "Start/Resume a session",
      step: "Step",
      stepDescription: "Show step description",
      description: "Description",
      category: "Category",
      reviewCards: "Review Cards",
      card: "Card",
      cards: "Cards",
      cardsDone: "Cards Done",
      nextStep: "Next Step",
      finish: "Finish",
      continue: "Continue",
      initialQuestion: "Initial Question",
      finalQuestion: "Final Question",
      dataSyncing: "Saving data...",
      dataSynced: "Data saved",
      helpCards:
        "If you put a card in the wrong folder by mistake, you can click on any folder and move the card to another folder from there.",

      // Results
      seeResults: "See Results",
      exportResults: "Export All Results (Excel)",
      selectTool: "Select a Tool",
      selectDate: "Select a Date",
      noResults:
        "No results yet, the person must first complete the questionnaire.",
      chartTab: "Chart",
      quotationTab: "Quotation",
      quotation: "Quotation Card",
      chart: "Chart",
      summary: "Summary",
      questions: "Questions",
      back: "Back",
      person: "Person",

      // Charts
      exportPDF: "Export PDF",
      flipChart: "Columns/Bars",

      // Patients
      addPatient: "Add a new Person",
      noPatients: "No people created yet.",
      createPatient: "Go to the People page to create a new one",
      namePseudo: "Pseudonym",
      deletePatient: "Delete Person {{patient}}",
      editPatient: "Edit Person {{patient}}",
      selectPatient: "Select a Person",
      inProgress: "session in progress",
      sociodemographic: "Sociodemographic Data",
      missing: "Missing, please fill out the questionnaire.",
      existing: "Already filled out, you can update it if needed.",
      openSurvey: "Fill out the Sociodemographic questionnaire",
      updateSurvey: "Update the Sociodemographic questionnaire",

      // Users
      user: "User",
      newUser: "New User",
      createUser: "Create a new User",
      deleteUser: "Delete User",
      deletingUser: "Deleting User...",
      deleteUserConfirmationMessage:
        "Are you sure you want to delete user {{user}}?",
      userRoles: "Roles",
      userPermissions: "Permissions",
      superAdminRole: "Super-Administrator",
      backToUsers: "Back to User list",

      // Profile
      myProfile: "My Profile",
      yearOfBirth: "Year of Birth",
      country: "Country",
      countries: countriesEN,
      region: "Region/Canton",
      specialty: "Discipline",
      specialtyPlaceholder:
        "Nurse, Doctor, Ergotherapist, Social Worker, Psychologist, ...",
      changePassword: "Change Password",
      leaveEmptyToNotChange: "(Leave empty to keep your current password)",
      passwordRepeat: "Repeat password",
      selectValue: "-- Select a value --",

      // User
      firstName: "First Name",
      lastName: "Last Name",
      email: "E-Mail / Login",
      password: "Password",
      passwordExplanation:
        "NOTE: This password is only valid for the user's first connection, he will need to change it directly.",

      // Tool
      moreDetails: "More details",

      // Validation
      required: "This field is required",
      tooLow:
        "The value is too low, the year of birth must be greater than {{min}}",
      tooHigh:
        "The value is too high, the year of birth cannot be greater than {{max}}",
      tooShort:
        "The value is too short, it needs to be at least{{minLength}} characters long",
      invalidEmail: "This is not a valide e-mail address",
      passwordsMustMatch: "Passwords must match",
    },
  },
  fr: {
    translation: {
      // Navbar
      home: "Accueil",
      patients: "Personnes",
      results: "Résultats",
      users: "Utilisateurs",
      usage: "Utilisation",
      logout: "Déconnexion",
      configuration: "Configuration",

      // Actions
      cancel: "Annuler",
      save: "Sauvegarder",
      add: "Ajouter",
      saving: "Sauvegarde en cours...",
      delete: "Supprimer",
      undo: "Revenir en arrière",

      // State
      loading: "Chargement...",

      // Questionnaire
      start: "Commencer/Reprendre une session",
      step: "Étape",
      stepDescription: "Montrer la description de l'étape",
      description: "Description",
      category: "Catégorie",
      reviewCards: "Revoir Cartes",
      card: "Carte",
      cards: "Cartes",
      cardsDone: "Cartes Terminées",
      nextStep: "Étape Suivante",
      finish: "Terminer",
      continue: "Continuer",
      initialQuestion: "Question(s) Initiale(s)",
      finalQuestion: "Question(s) Finale(s)",
      dataSyncing: "Sauvegarde en cours...",
      dataSynced: "Données sauvegardées",
      helpCards:
        "Si des cartes ont été mal placées par mégarde,  il est possible de cliquer sur un dossier et de déplacer une carte vers un autre dossier.",

      // Results
      seeResults: "Voir Résultats",
      exportResults: "Exporter tous les Résultats (Excel)",
      selectTool: "Sélectionnez l'outil",
      selectDate: "Sélectionnez une date",
      noResults:
        "Pas de résultats disponibles, la personne doit d'abord compléter le questionnaire.",
      quotationTab: "Cotation",
      chartTab: "Graphique",
      quotation: "Fiche de cotation",
      chart: "Graphique",
      summary: "Récapitulatif",
      questions: "Questions",
      back: "Retour",
      person: "Personne",

      // Charts
      exportPDF: "Télécharger PDF",
      flipChart: "Colonnes/Barres",

      // Patients
      addPatient: "Ajouter une nouvelle Personne",
      noPatients: "Aucune personne n'a été créée pour l'instant.",
      createPatient: "Aller à la page Personnes pour en créer une nouvelle.",
      namePseudo: "Pseudonyme",
      deletePatient: "Supprimer Personne {{patient}}",
      editPatient: "Modifier Personne {{patient}}",
      selectPatient: "Sélectionnez une personne",
      inProgress: "session en cours",
      sociodemographic: "Données socio-démographiques",
      missing: "Données manquantes, veuillez remplir le questionnaire.",
      existing: "Déjà saisies.",
      openSurvey: "Remplir le questionnaire sociodémographique",
      updateSurvey: "Mettre à jour le questionnaire sociodémographique",

      // Users
      user: "Utilisateur",
      newUser: "Nouvel Utilisateur",
      createUser: "Créer un nouvel utilisateur",
      deleteUser: "Supprimer Utilisateur",
      deletingUser: "Suppression en cours...",
      deleteUserConfirmationMessage:
        "Êtes-vous sûr(e) de vouloir supprimer l'utilisateur {{user}}?",
      userRoles: "Rôles",
      superAdminRole: "Super-Administrateur",
      userPermissions: "Permissions",
      backToUsers: "Retour vers la liste des utilisateurs",

      // Profile
      myProfile: "Mon Profil",
      yearOfBirth: "Année de naissance",
      country: "Pays",
      countries: countriesFR,
      region: "Région/Canton",
      specialty: "Discipline",
      specialtyPlaceholder:
        "Infirmier, Médecin, Ergo, Assistant Social, Psychologue, ...",
      changePassword: "Changer le mot de passe",
      leaveEmptyToNotChange:
        "(Laisser vide pour conserver votre mot de passe actuel)",
      passwordRepeat: "Répétez le mot de passe",
      selectValue: "-- Sélectionnez une valeur --",

      // User
      firstName: "Prénom",
      lastName: "Nom",
      email: "E-Mail / Login",
      password: "Mot de passe",
      passwordExplanation:
        "REMARQUE: Ce mot de passe sert uniquement à la première connexion de l'utilisateur, il devra le changer directement.",

      // Tool
      moreDetails: "Plus de détails",

      // Validation
      required: "Ce champ est requis",
      tooLow:
        "La valeur est trop petite, l'année de naissance doit être supérieure à {{min}}",
      tooHigh:
        "La valeur est trop grande, l'année de naissance doit être inférieure à {{max}}",
      // eslint-disable-next-line no-undef
      tooShort:
        "La valeur est trop courte, elle doit comporter au minimum {{minLength}} caractères",
      invalidEmail: "Ce n'est pas une adresse e-mail valide",
      passwordsMustMatch: "Les mots de passe doivent correspondre",
    },
  },
};
