import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { translations } from "./translations";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector) // detect language based on the browser
  .init({
    resources: translations,
    fallbackLng: "fr",
    lng: "fr",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
