import React, { useState } from "react";
import Card from "./Card";

import "./CardStack.css";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useTranslation } from "react-i18next";
import ReviewModal from "./ReviewModal";

export default function CardStack({
  cards,
  categories,
  goToNextStep,
  finalizeQuestionnaire,
  currentStep,
  nbSteps,
  answers,
  isSaving,
}) {
  const { t } = useTranslation();

  const [reviewModal, setReviewModal] = useState(false);

  const toggleCardsModal = () => {
    setReviewModal((m) => !m);
  };

  return (
    <>
      <div className="Card-stack">
        {cards.length > 1 ? (
          <div className="Card-stacked">
            <div
              className="Card-stacked Card-top"
              style={{ cursor: isSaving ? "default" : "pointer" }}
            >
              <Card {...cards[0]} isSaving={isSaving} />
            </div>
          </div>
        ) : cards.length > 0 ? (
          <div
            className="Card-stacked"
            style={{ cursor: isSaving ? "default" : "pointer" }}
          >
            <Card {...cards[0]} isSaving={isSaving} />
          </div>
        ) : currentStep + 1 < nbSteps ? (
          <div className="Next-step">
            <Button
              className="Stack-button"
              color="primary"
              onClick={toggleCardsModal}
            >
              {t("reviewCards")}
            </Button>
            <Button
              className="Stack-button"
              color="success"
              onClick={goToNextStep}
            >
              {t("nextStep")}
            </Button>
          </div>
        ) : (
          <div className="Next-step">
            <Button
              className="Stack-button"
              color="primary"
              onClick={toggleCardsModal}
            >
              {t("reviewCards")}
            </Button>
            <Button
              className="Stack-button"
              color="success"
              onClick={
                finalizeQuestionnaire ? finalizeQuestionnaire : goToNextStep
              }
            >
              {finalizeQuestionnaire ? t("finish") : t("continue")}
            </Button>
          </div>
        )}
      </div>
      <Modal
        isOpen={reviewModal}
        toggle={toggleCardsModal}
        className="Review-modal"
        centered
        size={"xl"}
      >
        <ModalHeader toggle={toggleCardsModal}>{t("cards")}</ModalHeader>
        <ModalBody className="Review-container">
          <ReviewModal categories={categories} answers={answers} />
        </ModalBody>
      </Modal>
    </>
  );
}
