import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardSubtitle,
  CardText,
  UncontrolledCollapse,
} from "reactstrap";

import "./ApplicationCard.css";
import { useTranslation } from "react-i18next";

export default function ApplicationCard({
  id,
  name,
  subtitle,
  description,
  descriptionLong,
  logo,
  onButtonClick,
  buttonTitle,
  onSecondaryButtonClick,
  secondaryButtonTitle,
  disabled,
}) {
  const { t } = useTranslation();

  return (
    <div className="Application-Card-Container">
      <Card className="Application-Card">
        <CardImg top width="100%" src={logo} alt={`Logo ${name}`} />
        <CardBody className="d-flex flex-column">
          {/*<CardTitle tag="h3">{name}</CardTitle>*/}
          <CardSubtitle
            tag="h5"
            className={`mb-2 text-muted Application-Title ${
              !descriptionLong ? "flex-grow-1" : ""
            }`}
          >
            {subtitle}
          </CardSubtitle>
          {description && (
            <>
              <CardText tag="h6" className="text-left flex-grow-1">
                {description}
              </CardText>
            </>
          )}

          {descriptionLong && (
            <div>
              <Button
                color="link"
                id={`more-details-${id}`}
                className="text-center mb-2"
              >
                + {t("moreDetails")}
              </Button>
              <UncontrolledCollapse toggler={`more-details-${id}`}>
                <h6 className="text-left pb-2 pt-2">
                  {nl2br(descriptionLong)}
                </h6>
              </UncontrolledCollapse>
            </div>
          )}

          <Button
            color={!disabled ? "success" : "secondary"}
            onClick={onButtonClick}
            disabled={disabled}
          >
            {buttonTitle}
          </Button>

          {secondaryButtonTitle && (
            <Button
              className={"mt-3"}
              color={!disabled ? "info" : "secondary"}
              onClick={onSecondaryButtonClick}
              disabled={disabled}
            >
              {secondaryButtonTitle}
            </Button>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

function nl2br(text) {
  return text.split("\n").map((item, key) => {
    return (
      <span key={key}>
        {item}
        <br />
      </span>
    );
  });
}
