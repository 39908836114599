import React from "react";
import Header from "../components/Header";
import PatientList from "../components/PatientList";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "@react-keycloak/web";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ApplicationCard from "../components/ApplicationCard";
import { useTools } from "../context/ToolsContext";

import { Backend } from "../services/backend";
import { downloadFile } from "../utils/download-file";

export default function ResultsMenu() {
  const { keycloak } = useKeycloak();

  const { tools } = useTools();

  const { t } = useTranslation();

  const history = useHistory();

  const { tool: toolSlug, patient } = useParams();

  const handleToolClick = (e, toolSlug) => {
    history.push(`/resultats/${toolSlug}`);
  };

  const handlePatientClick = (e, patient) => {
    history.push(`/resultats/${toolSlug}/${patient.id}`);
  };

  const handleExportClick = async (e, toolSlug) => {
    console.log("Export CSV", toolSlug);
    let response = await Backend.exportRecords(keycloak.token, toolSlug);
    await downloadFile(response);
  };

  if (!tools)
    return (
      <div style={{ height: "100vh", lineHeight: "100vh" }}>
        <FontAwesomeIcon icon="spinner" spin size="3x" title={t("loading")} />
      </div>
    );

  return (
    <>
      <div className="Nav-header">
        <Header />
      </div>
      <div className="Grow-flex-top">
        {!toolSlug && !patient && (
          <>
            <h1>{t("results")}</h1>
            <h2>{t("selectTool")}</h2>
            <div className="d-flex flex-column flex-md-row justify-content-around align-self-stretch m-3">
              {tools.map(
                ({ id, slug, name, subtitle, image, users: toolUsers }) => {
                  if (
                    toolUsers.find(
                      (u) => u.keycloakId === keycloak.tokenParsed.sub
                    )
                  )
                    return (
                      <ApplicationCard
                        key={id}
                        id={id}
                        name={name}
                        subtitle={subtitle}
                        logo={`https://via.placeholder.com/500x150?text=${name}`}
                        onButtonClick={(e) => {
                          handleToolClick(e, slug);
                        }}
                        buttonTitle={t("seeResults")}
                        onSecondaryButtonClick={(e) =>
                          handleExportClick(e, slug)
                        }
                        secondaryButtonTitle={t("exportResults")}
                      />
                    );
                  else return null;
                }
              )}
            </div>
          </>
        )}

        {toolSlug && !patient && (
          <>
            <h1>
              {t("results")} - {tools.find((t) => t.slug === toolSlug).name}
            </h1>
            <h2>{t("selectPatient")}</h2>
            <PatientList
              readOnly={true}
              handlePatientClick={handlePatientClick}
            />
          </>
        )}
      </div>
    </>
  );
}
