import React, { useEffect, useState } from "react";

import "./PlayArea.css";
import Category from "./Category";
import CardStack from "./CardStack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import _ from "lodash";
import { usePrevious } from "../custom-hooks/usePrevious";
import { useTranslation } from "react-i18next";
import Card from "./Card";
import { useKeycloak } from "@react-keycloak/web";
import { Backend } from "../services/backend";
import { createHistory, getMoveNumber } from "../utils/redcap";
import { Badge, UncontrolledTooltip } from "reactstrap";

export default function PlayArea({
  categories,
  cards,
  answers,
  goToNextStep,
  finalizeQuestionnaire,
  currentStep,
  nbSteps,
  updateAnswer,
  record,
}) {
  const { t } = useTranslation();

  const { keycloak } = useKeycloak();

  // Initialization state
  let [initialized, setInitialized] = useState(false);

  // Initial syncing state
  let [hasSynced, setHasSynced] = useState(false);

  // Saving state
  let [isSaving, setIsSaving] = useState(false);

  // Current move number
  let [moveNumber, setMoveNumber] = useState(0);

  // Current play history
  let [history, setHistory] = useState([]);

  // Check currently open category
  let [openCategoryName, setOpenCategoryName] = useState(null);

  // Store previous value of cards
  let prevStep = usePrevious(currentStep);

  // Save an answer (and set 'hasSynced' state)
  const setAnswerSaving = (saving) => {
    setHasSynced(true);
    setIsSaving(saving);
  };

  // Restore state of the session based on currently stored data
  useEffect(() => {
    if (cards && categories && answers && !initialized) {
      // Parse move number from existing answers
      let moveNumber = getMoveNumber(cards, answers);
      setMoveNumber(moveNumber);

      // Create move history from existing answers
      let history = createHistory(cards, categories, answers, moveNumber);
      setHistory(history);

      // if (moveNumber) setMoveNumber(moveNumber);

      // if (history) setHistory(history);

      setInitialized(true);
    }
  }, [cards, categories, answers, initialized]);

  // React to step change
  useEffect(() => {
    if (initialized) {
      if (prevStep !== currentStep || history.length === 0) {
        console.log("step changed from", prevStep, currentStep);

        setMoveNumber(0);
        setHistory([
          {
            cardStack: cards.map((c) => c.variable),
            categoryCards: categories.reduce((acc, category) => {
              acc[category.value] = [];
              return acc;
            }, {}),
          },
        ]);
      }
    }
  }, [initialized, cards, categories, prevStep, currentStep, history.length]);

  // Check if history is in sync with the categories
  let historyInSync =
    categories &&
    history &&
    history[moveNumber] &&
    Object.keys(history[moveNumber].categoryCards).includes(
      categories[0].value + "" // Cast it to a string, as this is what Object.keys returns
    ) &&
    history[moveNumber].categoryCards[categories[0].value];

  // Record a card move to the history
  const moveCard = async (card, category) => {
    // TODO - Do this only at the end?
    // Save answer to the server
    setAnswerSaving(true);

    await Backend.saveAnswer(
      keycloak.token,
      record.id,
      card.variable,
      category
    );

    setAnswerSaving(false);

    // Update the current answers with the new one
    updateAnswer(card.variable, category);

    // Slice history (to forget future moves)
    let truncatedHistory = history.slice(0, moveNumber + 1);

    // Clone current state
    let currentState = _.cloneDeep({ ...truncatedHistory[moveNumber] });

    // Check if we are dragging from the stack or a folder
    if (!card.fromFolder) {
      // Create new state by moving a card from the stack to the category
      let cardToAdd = currentState.cardStack.shift();
      currentState.categoryCards[category].unshift(cardToAdd);

      // Update the history with the current state
      setHistory(() => [...truncatedHistory, currentState]);

      // Update the state
      setMoveNumber((n) => n + 1);
    } else {
      // Create new state by removing the card from the category and move it to the new category

      // TODO - This might be done more efficiently

      // Rewrite all history by removing this card from the previous category
      for (let [index, historyStep] of truncatedHistory.entries()) {
        if (index === 0) {
          // Replace the stack for the first step
          let cardIndexInStack = historyStep.cardStack.findIndex(
            (v) => v === card.variable
          );

          historyStep.cardStack.splice(cardIndexInStack, 1);

          historyStep.cardStack.splice(
            moveNumber - index - 1,
            0,
            card.variable
          );
        } else {
          // Just shift the previous stack for the other steps
          historyStep.cardStack = truncatedHistory[index - 1].cardStack.slice(
            1
          );

          // Recreate category cards based on original data (except for the moved card)
          historyStep.categoryCards = _.cloneDeep(
            truncatedHistory[index - 1].categoryCards
          );

          let cardToAdd = truncatedHistory[index - 1].cardStack[0];

          // Identify the previous category of the card (except for the moved card)
          let categoryOfCard = null;

          if (cardToAdd === card.variable) {
            categoryOfCard = category;
          } else {
            for (let categoryValue in currentState.categoryCards) {
              if (
                currentState.categoryCards[categoryValue].includes(cardToAdd)
              ) {
                categoryOfCard = categoryValue;
                break;
              }
            }
          }

          historyStep.categoryCards[categoryOfCard].push(cardToAdd);
        }
      }

      // Update the history with the rewritten one
      setHistory(truncatedHistory);

      // Update the move number
      setMoveNumber(moveNumber);
    }
  };

  // Cancel a card move
  const goBack = async () => {
    // Delete answer of the previous question on the server
    let prevVariable = history[moveNumber - 1].cardStack[0];
    console.log("Variable of previous answer", prevVariable);

    setAnswerSaving(true);

    await Backend.saveAnswer(keycloak.token, record.id, prevVariable, "");

    setAnswerSaving(false);

    setMoveNumber((n) => n - 1);
  };

  // Handle click on category (show cards at the bottom and allow rearranging)
  const handleCategoryClick = (categoryName) => {
    setOpenCategoryName((openCategory) => (openCategory ? null : categoryName));
  };

  // Make list of cards based on their variable name
  const makeCardListFromVariables = (variables) => {
    if (!variables) return [];

    let foundCards = [];

    for (let variable of variables) {
      let card = cards.find((c) => c.variable === variable);
      foundCards.push(card);
    }

    return foundCards;
  };

  // Transform all lists of variables in an object to cards
  const transformCategoryVariablesToCards = (categoryCardVariables) => {
    return Object.keys(categoryCardVariables).reduce((acc, curr) => {
      acc[curr] = makeCardListFromVariables(categoryCardVariables[curr]);
      return acc;
    }, {});
  };

  return cards && categories && initialized && historyInSync ? (
    <div className="Play-area">
      <div className="Data-sync">
        {hasSynced &&
          (isSaving ? (
            <Badge color="secondary">
              <FontAwesomeIcon icon="spinner" spin title={t("dataSyncing")} />{" "}
              {t("dataSyncing")}
            </Badge>
          ) : (
            <Badge color="success">
              <FontAwesomeIcon icon="check-circle" title={t("dataSynced")} />{" "}
              {t("dataSynced")}
            </Badge>
          ))}
      </div>
      <div className="Play-row">
        {categories.map((category) => (
          <Category
            key={category.name}
            value={category.value}
            moveCard={moveCard}
            cards={makeCardListFromVariables(
              history[moveNumber].categoryCards[category.value]
            )}
            handleCategoryClick={handleCategoryClick}
            {...category}
          />
        ))}
      </div>
      <div className="Play-row" style={{ gridAutoFlow: "row" }}>
        <CardStack
          cards={makeCardListFromVariables(history[moveNumber].cardStack)}
          categories={categories}
          goToNextStep={goToNextStep}
          currentStep={currentStep}
          nbSteps={nbSteps}
          answers={transformCategoryVariablesToCards(
            history[moveNumber].categoryCards
          )}
          finalizeQuestionnaire={finalizeQuestionnaire}
          isSaving={isSaving}
        />
        <div
          className="mt-3"
          style={{ fontSize: "smaller", alignSelf: "flex-end" }}
        >
          {moveNumber > 0 && (
            <FontAwesomeIcon
              icon="undo"
              title={t("undo")}
              onClick={!isSaving ? goBack : () => {}}
              className="mr-2"
              style={{ cursor: !isSaving ? "pointer" : "default" }}
            />
          )}
          {moveNumber + 1 <= cards.length ? (
            <span>
              {t("card")} {moveNumber + 1}/{cards.length}
            </span>
          ) : (
            <span>{t("cardsDone")}</span>
          )}{" "}
          <FontAwesomeIcon
            className="Help-cards"
            icon="question-circle"
            title={t("helpCards")}
            id="help-cards"
          />
          <UncontrolledTooltip placement="top" target="help-cards">
            {t("helpCards")}
          </UncontrolledTooltip>
        </div>
      </div>
      {openCategoryName && (
        <div className="Play-folder-content">
          <div
            className="Play-folder-indicator"
            style={{
              left: `${
                (100 / categories.length) *
                  categories.findIndex((c) => c.name === openCategoryName) +
                100 / categories.length / 2
              }%`,
            }}
          />
          <h2 className="mt-2">{openCategoryName}</h2>
          <div className="Play-folder-items-container">
            <div className="Play-folder-items">
              {[
                ...history[moveNumber].categoryCards[
                  categories.find((c) => c.name === openCategoryName).value
                ],
              ]
                .reverse()
                .map((cardVariable) => {
                  let card = cards.find((c) => c.variable === cardVariable);

                  return (
                    <div
                      className="Play-folder-card"
                      key={card.variable}
                      style={{ cursor: isSaving ? "default" : "pointer" }}
                    >
                      <Card {...card} fromFolder={true} isSaving={isSaving} />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </div>
  ) : (
    <div>Loading...</div>
  );
}
