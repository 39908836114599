import React from "react";

import "./App.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import Questionnaire from "./pages/Questionnaire";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import Results from "./pages/Results";
import NotFound from "./components/NotFound";
import Patients from "./pages/Patients";
import { useUser } from "./context/UserContext";
import ResultsMenu from "./pages/ResultsMenu";
import { useKeycloak } from "@react-keycloak/web";
import { ProtectedRoute } from "./utils/ProtectedRoute";
import Users from "./pages/Users";
import User from "./pages/User";
import Profile from "./pages/Profile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

function App() {
  const { initialized } = useKeycloak();
  const { roles } = useUser();
  const { t } = useTranslation();

  return initialized && roles ? (
    <Router>
      <div className="App">
        <DndProvider
          backend={TouchBackend}
          options={{
            enableMouseEvents: true,
            enableKeyboardEvents: true,
            delayTouchStart: 80,
          }}
        >
          <Switch>
            <Route path="/" exact component={Home} />
            <Route
              path="/questionnaire/:tool/:patient"
              component={Questionnaire}
            />
            <Route path="/resultats" exact component={ResultsMenu} />
            <Route path="/resultats/:tool" exact component={ResultsMenu} />
            <Route path="/resultats/:tool/:patient" exact component={Results} />
            <Route
              path="/resultats/:tool/:patient/:record(\d+)"
              exact
              component={Results}
            />
            <Route
              path="/resultats/:tool/:patient/:tab(\w+)"
              exact
              component={Results}
            />
            <Route
              path="/resultats/:tool/:patient/:record(\d+)/:tab"
              exact
              component={Results}
            />
            <Route path="/personnes" component={Patients} />
            <Route path="/profil" component={Profile} />
            <ProtectedRoute path="/utilisateurs" exact component={Users} />
            <ProtectedRoute
              path="/utilisateurs/nouveau"
              exact
              component={User}
              create={true}
            />
            <ProtectedRoute path="/utilisateurs/:userID" component={User} />
            <Route component={NotFound} />
          </Switch>
        </DndProvider>
      </div>
    </Router>
  ) : (
    <div className="App">
      <div style={{ height: "100vh", lineHeight: "100vh", fontSize: "medium" }}>
        <FontAwesomeIcon icon="spinner" spin size="3x" title={t("loading")} />
      </div>
    </div>
  );
}

export default App;
