import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./localization/i18n";
import registerFontAwesomeIcons from "./registerFontAwesomeIcons";
import { ReactKeycloakProvider } from "@react-keycloak/web";

import keycloak from "./utils/keycloak";
import UserProvider from "./context/UserContext";
import ToolsProvider from "./context/ToolsContext";

console.log(keycloak);

// Register the FontAwesome Icons
registerFontAwesomeIcons();

ReactDOM.render(
  <React.StrictMode>
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        onLoad: "login-required",
        checkLoginIframe: false,
      }}
    >
      <UserProvider>
        <ToolsProvider>
          <App />
        </ToolsProvider>
      </UserProvider>
    </ReactKeycloakProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
