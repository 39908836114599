import React from "react";
import { FormFeedback, FormGroup, FormText, Input, Label } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Field, useFormikContext } from "formik";
import "./MySelectGroup.css";

export default function MySelectGroup({
  fieldName,
  text,
  options,
  label,
  placeholder,
  ...props
}) {
  const { t } = useTranslation();

  const { errors, touched } = useFormikContext();

  return (
    <FormGroup className="select-group">
      {label !== false && (
        <Label htmlFor={fieldName}>{!label ? t(fieldName) : label}</Label>
      )}
      <Input
        {...props}
        tag={Field}
        as="select"
        type="select"
        name={fieldName}
        id={fieldName}
        placeholder={placeholder ? placeholder : t(fieldName)}
        valid={!errors[fieldName] && touched[fieldName]}
        invalid={errors[fieldName] && touched[fieldName]}
      >
        <option key="empty" value="">
          {t("selectValue")}
        </option>
        {Object.keys(options).map((key) => (
          <option key={key} value={key}>
            {options[key]}
          </option>
        ))}
      </Input>
      <FormFeedback>{errors[fieldName]}</FormFeedback>
      {text && <FormText color="muted">{text}</FormText>}
    </FormGroup>
  );
}
