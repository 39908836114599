import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Nav,
  NavItem,
  NavLink,
  Table,
  TabContent,
  TabPane,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useParams,
  Link as RouterLink,
  generatePath,
  useRouteMatch,
  useHistory,
} from "react-router-dom";

import _ from "lodash";

import Charts from "../components/Charts";

import "./Results.css";
import { Backend } from "../services/backend";
import { useKeycloak } from "@react-keycloak/web";
import {
  ANSWERED_CHOICE,
  CARDS_PREFIX,
  CHECKBOX_ANSWER_SEPARATOR,
  COMPLETE_SUFFIX,
  parseQuestionnaire,
  QUESTIONNAIRE_STATUS,
} from "../utils/redcap";

import moment from "moment";
import { useTools } from "../context/ToolsContext";

const SHORT_DATE_FORMAT = "DD.MM.YYYY";
const SHORT_DATE_FORMAT_FILE = "DD-MM-YYYY";
const DATE_FORMAT = "DD.MM.YYYY HH:mm";
const CARD_LETTER = "c";
const STEP_LETTER = "t";

const DEFAULT_TAB = "quotation";

function isBoolean(s) {
  return s === "true" || s === "false";
}

function TextQuestion({ question, answers }) {
  return (
    <div className="question">
      <p>
        <strong>{question.field_label}</strong>
      </p>
      <div className="question-answer">{answers[question.field_name]}</div>
    </div>
  );
}

function CheckboxQuestion({ question, answers }) {
  return (
    <div className="question">
      <p>
        <strong>{question.field_label}</strong>
      </p>
      <div className="question-answer">
        {question.choices
          .filter((c) => c.value !== ANSWERED_CHOICE)
          .map((c) => (
            <div key={c.value}>
              <input
                type="checkbox"
                disabled
                checked={
                  +answers[
                    `${
                      question.field_name
                    }${CHECKBOX_ANSWER_SEPARATOR}${c.value.toLowerCase()}`
                  ] === 1
                }
              />{" "}
              <label>
                {c.value} - {c.label}
              </label>
            </div>
          ))}
      </div>
    </div>
  );
}

function Questions({ answers, questions }) {
  return questions.map((question, index) => {
    // Text question or checkbox
    if (!question.choices) {
      return <TextQuestion key={index} answers={answers} question={question} />;
    } else {
      return (
        <CheckboxQuestion key={index} answers={answers} question={question} />
      );
    }
  });
}

export default function Results() {
  const { t } = useTranslation();

  const { keycloak } = useKeycloak();

  const { tools } = useTools();

  // Get tool slug & patient ID from the URL
  const {
    tool: toolSlug,
    patient: patientID,
    record: recordID,
    tab,
  } = useParams();

  // Get current route match
  const routeMatch = useRouteMatch();

  // History to redirect to other routes
  const history = useHistory();

  // Tool
  const [tool, setTool] = useState(null);

  // Patient
  const [patient, setPatient] = useState(null);

  // Questionnaire
  const [questionnaire, setQuestionnaire] = useState(null);

  // Questionnaire instruments
  const [instruments, setInstruments] = useState(null);

  // Records for the current patient
  const [records, setRecords] = useState(null);

  // Currently selected Record
  const [currentRecord, setCurrentRecord] = useState(null);

  const [isLoaded, setIsLoaded] = useState(false);

  const [answers, setAnswers] = useState(null);

  // Define current tab
  const currentTab = useMemo(() => {
    if (!tab) return DEFAULT_TAB;
    else return tab;
  }, [tab]);

  // Load tool
  useEffect(() => {
    if (tools && toolSlug) {
      setTool(tools.find((t) => t.slug === toolSlug));
    }
  }, [keycloak.token, tools, toolSlug]);

  // Load questionnaire
  useEffect(() => {
    async function fetchQuestionnaire() {
      // Find the questionnaire metadata based on the URL slug
      const metadata = await Backend.fetchMetadata(keycloak.token, toolSlug);

      // Parse & set the questionnaire
      let questionnaire = parseQuestionnaire(metadata);

      setQuestionnaire(questionnaire);

      // Find the questionnaire instruments based on the URL slug
      const instruments = await Backend.fetchInstruments(
        keycloak.token,
        toolSlug
      );

      // Set the instruments
      setInstruments(instruments);
    }

    if (toolSlug) fetchQuestionnaire();
  }, [keycloak.token, toolSlug]);

  // Load finished records for this tool & patient
  useEffect(() => {
    async function fetchFinishedRecords() {
      let finishedRecords = await Backend.getPatientToolRecords(
        keycloak.token,
        toolSlug,
        patientID,
        true
      );

      setRecords(finishedRecords);
    }

    if (patientID && toolSlug) fetchFinishedRecords();
  }, [keycloak.token, patientID, toolSlug]);

  // Define current record based on the latest one
  useEffect(() => {
    if (records) {
      // Sort by descending order of update time
      let sortedRecords = [...records].sort(
        (r1, r2) => r2.updatedAt - r1.updatedAt
      );

      // Set the latest record as the current one
      if (!recordID) setCurrentRecord(sortedRecords[0]);
      else setCurrentRecord(records.find((r) => r.id === +recordID));
    } else {
      setIsLoaded(true);
    }
  }, [records, recordID]);

  // Load answers if a specific record is selected
  useEffect(() => {
    async function fetchAnswers() {
      let answers = await Backend.getAnswers(keycloak.token, currentRecord.id);
      setAnswers(answers);
      setIsLoaded(true);
    }

    if (currentRecord) {
      fetchAnswers();
    }
  }, [keycloak.token, currentRecord]);

  // Load patient
  useEffect(() => {
    async function fetchPatient() {
      const patient = await Backend.getPatients(keycloak.token, patientID);
      setPatient(patient);
    }

    if (patientID) fetchPatient();
  }, [keycloak.token, patientID]);

  const toggleTab = (tab) => {
    console.log(routeMatch);
    console.log(tab);

    // Add or replace tab param
    let hasTab = routeMatch.params.tab;

    let path = hasTab ? routeMatch.path : `${routeMatch.path}/:tab`;

    let finalPath = generatePath(path, { ...routeMatch.params, tab: tab });

    history.push(finalPath);
  };

  // Define cards by instrument
  const cards = useMemo(() => {
    if (!questionnaire) return [];

    // Groups steps by category to figure out the correct cards for each
    let categorySteps = _.groupBy(questionnaire.steps, "category");

    let cards = {};

    // For each instrument
    // Get list of all cards (based on the first step currently)
    // This assumes that all the data is harmonized and correct in RedCap
    for (let category in categorySteps) {
      let categoryCards = categorySteps[category][0].cards.map((c) => ({
        suffix: c.variable.substr(c.variable.indexOf(CARD_LETTER)),
        title: c.title,
        icon: c.icon,
      }));

      cards[category] = categoryCards;
    }

    return cards;
  }, [questionnaire]);

  const answersByCard = useMemo(() => {
    let answersByCard = {};

    if (!questionnaire || !answers || Object.keys(cards).length === 0)
      return answersByCard;

    // Go through all steps and get answers for all cards
    for (let step of questionnaire.steps) {
      for (let card of Object.values(cards).flat()) {
        if (!Object.keys(answersByCard).includes(card.suffix))
          answersByCard[card.suffix] = {};

        answersByCard[card.suffix][step.number] =
          answers[`${STEP_LETTER}${step.number}${card.suffix}`];
      }
    }

    return answersByCard;
  }, [questionnaire, answers, cards]);

  const existingCardSuffixes = useMemo(() => {
    if (
      cards &&
      questionnaire &&
      questionnaire.steps &&
      Object.keys(answersByCard).length > 0
    ) {
      const allBooleanSteps = questionnaire.steps
        .filter((s) => isBoolean(s.categories[0].value))
        .map((s) => s.number);

      const suffixes = Object.values(cards)
        .flat()
        .filter(
          (c) => !allBooleanSteps.some((s) => answersByCard[c.suffix][s] === "")
        )
        .map((c) => c.suffix);

      return suffixes;
    } else {
      return [];
    }
  }, [cards, questionnaire, answersByCard]);

  const dataForBarChart = useMemo(() => {
    let charts = {};

    if (
      questionnaire &&
      instruments &&
      answers &&
      Object.keys(cards).length > 0
    ) {
      // Fill out card titles
      for (let instrumentName in cards) {
        let categories = [];

        for (let card of cards[instrumentName].filter((c) =>
          existingCardSuffixes.includes(c.suffix)
        )) {
          if (Object.keys(cards).length === 1) {
            categories.push(card.title);
          } else {
            //categories.push(`Some stuff - ${card.title}`);
            categories.push(card.title);
          }
        }

        charts[instrumentName] = { series: [] };
        charts[instrumentName].categories = categories;
      }

      for (let step of questionnaire.steps) {
        // TODO - This might need more advanced processing depending on the questionnaire
        // If the step is not boolean and numeric (no letters)
        // include it in the chart, otherwise it doesn't make sense to show it
        if (
          !isNaN(step.categories[0].value) &&
          !isBoolean(step.categories[0].value)
        ) {
          let seriesName;

          if (Object.keys(cards).length === 1) seriesName = step.title;
          else seriesName = `${step.title}`;

          let newSeries = {
            name: seriesName,
            data: [],
            stack: `${step.title}`,
          };

          for (let card of cards[step.category].filter((c) =>
            existingCardSuffixes.includes(c.suffix)
          )) {
            newSeries.data.push({
              name: seriesName,
              y: +answers[`${STEP_LETTER}${step.number}${card.suffix}`],
            });
          }

          charts[step.category].series.push(newSeries);
        }
      }
    }

    return charts;
  }, [questionnaire, answers, cards, instruments, existingCardSuffixes]);

  const questionnaireFinished = answers
    ? Object.keys(answers)
        .filter(
          (field) =>
            !field.startsWith(CARDS_PREFIX) && field.endsWith(COMPLETE_SUFFIX)
        )
        .every((field) => +answers[field] === +QUESTIONNAIRE_STATUS.FINISHED)
    : false;

  const nonBooleanStepsForInstrument = (instrument) =>
    questionnaire.steps.filter(
      (s) =>
        !isBoolean(s.categories[0].value) &&
        s.category === instrument.instrument_name
    );

  return (
    <div className="results-container">
      {questionnaire && instruments && isLoaded ? (
        !answers || !questionnaireFinished ? (
          <>
            <Nav pills className="results-tabs">
              <NavItem>
                <Button
                  tag={RouterLink}
                  to={"/resultats"}
                  color="secondary"
                  className="mr-2 px-3 py-2 border-0"
                >
                  <FontAwesomeIcon icon="caret-left" /> {t("back")}
                </Button>
              </NavItem>
            </Nav>

            <div>
              <h3 className="m-3">{t("noResults")}</h3>
            </div>
          </>
        ) : (
          <>
            <Nav pills className="results-tabs">
              <NavItem>
                <Button
                  tag={RouterLink}
                  to={"/resultats"}
                  color="secondary"
                  className="mr-2 px-3 py-2 border-0"
                >
                  <FontAwesomeIcon icon="caret-left" /> {t("back")}
                </Button>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: currentTab === "quotation" })}
                  onClick={() => {
                    toggleTab("quotation");
                  }}
                >
                  <FontAwesomeIcon icon="table" /> {t("quotationTab")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: currentTab === "chart" })}
                  onClick={() => {
                    toggleTab("chart");
                  }}
                >
                  <FontAwesomeIcon icon="chart-bar" /> {t("chartTab")}
                </NavLink>
              </NavItem>
            </Nav>
            {records.length > 1 ? (
              <div className="record-selection mb-3">
                <h4>
                  Patient "{patient.pseudonym}" - {t("selectDate")}
                </h4>
                <Nav
                  pills
                  className="d-flex align-items-center justify-content-center"
                >
                  {records
                    .sort((r1, r2) => r1.createdAt - r2.createdAt)
                    .map((record, index) => (
                      <NavItem key={record.id}>
                        <Button
                          tag={RouterLink}
                          to={
                            currentTab
                              ? `/resultats/${toolSlug}/${patientID}/${record.id}/${currentTab}`
                              : `/resultats/${toolSlug}/${patientID}/${record.id}`
                          }
                          color={
                            (recordID && +recordID === record.id) ||
                            (!recordID && index === records.length - 1)
                              ? "success"
                              : "secondary"
                          }
                          className="mr-2 px-3 py-2 border-0"
                        >
                          <FontAwesomeIcon icon="calendar" />{" "}
                          {moment(record.createdAt).format(SHORT_DATE_FORMAT)}
                        </Button>
                      </NavItem>
                    ))}
                </Nav>
              </div>
            ) : (
              <h4>Patient "{patient.pseudonym}"</h4>
            )}
            <TabContent activeTab={currentTab} className="results-tab-content">
              <TabPane tabId="quotation">
                <h4>
                  {t("quotation")}
                  {tool && ` - ${tool.name}`}
                </h4>
                <div className="patient-info">
                  <div>
                    <p>
                      <strong>
                        {t("person")} - {t("namePseudo")}
                      </strong>
                    </p>
                    <p>{patient.pseudonym}</p>
                  </div>
                  <div>
                    <p>
                      <strong>Date</strong>
                    </p>
                    <p>{moment(currentRecord.createdAt).format(DATE_FORMAT)}</p>
                  </div>
                </div>
                <hr />
                {(questionnaire.initialQuestions.length > 0 ||
                  questionnaire.finalQuestions.length > 0) && (
                  <h4>{t("questions")}</h4>
                )}
                <Questions
                  answers={answers}
                  questions={questionnaire.initialQuestions}
                />
                <Questions
                  answers={answers}
                  questions={questionnaire.finalQuestions}
                />
                <h4>{t("summary")}</h4>
                {instruments.map((instrument) => (
                  <React.Fragment key={instrument.instrument_label}>
                    {instruments.length > 1 && (
                      <h5>{instrument.instrument_label}</h5>
                    )}
                    <div className="results-table">
                      <Table striped>
                        <thead>
                          <tr>
                            <th></th>
                            {nonBooleanStepsForInstrument(instrument).map(
                              (step) => (
                                <th key={`header-${step.title}`}>
                                  {step.title}
                                </th>
                              )
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {cards[instrument.instrument_name]
                            .filter((c) =>
                              existingCardSuffixes.includes(c.suffix)
                            )
                            .map((card) => (
                              <tr key={card.suffix}>
                                <td className="results-card-title">
                                  <span
                                    id={`card-title-${card.suffix}`}
                                    className="results-card-title-text"
                                  >
                                    {card.title}
                                  </span>
                                  <UncontrolledTooltip
                                    placement="right"
                                    target={`card-title-${card.suffix}`}
                                  >
                                    <img
                                      src={card.icon}
                                      alt={card.title}
                                      style={{ maxWidth: "150px" }}
                                    />
                                  </UncontrolledTooltip>
                                </td>
                                {questionnaire.steps
                                  .filter(
                                    (s) =>
                                      !isBoolean(s.categories[0].value) &&
                                      s.category === instrument.instrument_name
                                  )
                                  .map((step, index) => (
                                    <td
                                      key={`answer-${card.suffix}-${step.number}`}
                                    >
                                      {answersByCard[card.suffix][
                                        step.number
                                      ] !== ""
                                        ? answersByCard[card.suffix][
                                            step.number
                                          ]
                                        : "-"}
                                    </td>
                                  ))}
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </React.Fragment>
                ))}
              </TabPane>
              <TabPane tabId="chart" id="chart-pane">
                <Charts
                  title={`${t("chart")} ${tool.name} - ${_.capitalize(
                    t("patient")
                  )} "${patient.pseudonym}" (${moment(
                    currentRecord.createdAt
                  ).format(SHORT_DATE_FORMAT)})`}
                  data={dataForBarChart}
                  instruments={instruments}
                  filename={`${tool.name}-${patient.pseudonym}-${moment(
                    currentRecord.createdAt
                  ).format(SHORT_DATE_FORMAT_FILE)}`}
                />
              </TabPane>
            </TabContent>
          </>
        )
      ) : (
        <div style={{ height: "100vh", lineHeight: "100vh" }}>
          <FontAwesomeIcon icon="spinner" spin size="3x" title={t("loading")} />
        </div>
      )}
    </div>
  );
}
