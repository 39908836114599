import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink,
  Button,
} from "reactstrap";

import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Header.css";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "@react-keycloak/web";
import { KEYCLOAK_ADMIN_ROLE, useUser } from "../context/UserContext";

export default function Header() {
  const { t } = useTranslation();

  const { roles } = useUser();

  let [isOpen, setIsOpen] = useState(false);

  let { keycloak } = useKeycloak();

  let toggle = () => {
    setIsOpen((o) => !o);
  };

  let handleLogoutClick = () => {
    keycloak.logout();
  };

  return (
    <div>
      <Navbar light expand="md" className="custom-navbar">
        <NavbarBrand href="#">Q-Sort</NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavLink to="/" exact tag={RouterNavLink}>
              <FontAwesomeIcon icon="home" /> {t("home")}
            </NavLink>
            <NavLink to="/personnes" tag={RouterNavLink}>
              <FontAwesomeIcon icon="hospital-user" /> {t("patients")}
            </NavLink>
            <NavLink to="/resultats" tag={RouterNavLink}>
              <FontAwesomeIcon icon="chart-bar" /> {t("results")}
            </NavLink>
            {roles && roles.includes(KEYCLOAK_ADMIN_ROLE) && (
              <NavLink to="/utilisateurs" tag={RouterNavLink}>
                <FontAwesomeIcon icon="users" /> {t("users")}
              </NavLink>
            )}
            {/*<NavLink to="/utilisation" tag={RouterNavLink}>
              <FontAwesomeIcon icon="question-circle" /> {t("usage")}
            </NavLink>
            <NavLink to="/configuration" tag={RouterNavLink}>
              <FontAwesomeIcon icon="cog" /> {t("configuration")}
            </NavLink>*/}
          </Nav>
          {keycloak.authenticated && (
            <>
              <NavLink to="/profil" tag={RouterNavLink}>
                <FontAwesomeIcon icon="user" /> {t("myProfile")}
              </NavLink>
              <Button color="link" onClick={handleLogoutClick}>
                <FontAwesomeIcon icon="sign-out-alt" /> {t("logout")}
              </Button>
            </>
          )}
        </Collapse>
      </Navbar>
    </div>
  );
}
