import React, { useEffect } from "react";

import { useDrag } from "react-dnd";

import "./Card.css";
import { ItemTypes } from "../constants";
import { getEmptyImage } from "react-dnd-html5-backend";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Card({
  variable,
  title,
  icon,
  showTitle,
  fromFolder,
  isSaving,
}) {
  const [{ opacity }, dragRef, preview] = useDrag({
    item: {
      type: ItemTypes.CARD,
      variable: variable,
      icon: icon,
      title: title,
      fromFolder: fromFolder,
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.2 : 1,
    }),
    canDrag: !isSaving,
  });
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);
  return (
    <>
      <div ref={dragRef} className="Card" style={{ opacity }}>
        {isSaving && (
          <div className={fromFolder ? "Card-spinner-folder" : "Card-spinner"}>
            <FontAwesomeIcon
              icon="spinner"
              spin
              size={fromFolder ? "2x" : "3x"}
            />
          </div>
        )}
        <img src={icon ? icon : ""} alt={title} className="Card-image" />
        {showTitle && <span>{title}</span>}
      </div>
    </>
  );
}
