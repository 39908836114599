import React from "react";

import "./ReviewModal.css";

export default function ReviewModal({ categories, answers }) {
  return (
    <div className="Review-modal-wrapper">
      {categories.map((category) => (
        <div className="Review-category">
          <p className="m-0 ml-2">{category.name}</p>
          <div key={category.name} className="Review-cards-container m-2">
            {
              /* Reverse the order of the answers to show them in ascending order */
              [...answers[category.value]].reverse().map((card) => (
                <div className="Review-card " key={card.variable}>
                  <div className="Card">
                    {/*<FontAwesomeIcon icon={card.icon} size="4x" />*/}
                    <img
                      src={card.icon}
                      alt={card.title}
                      className="Card-image"
                      width="80%"
                    />
                    {/*<span>{card.title}</span>*/}
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      ))}
    </div>
  );
}
