import React, { useEffect, useState } from "react";

import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";

import { Backend } from "../services/backend";

import "./Question.css";
import { useKeycloak } from "@react-keycloak/web";
import { ANSWERED_CHOICE, CHECKBOX_ANSWER_SEPARATOR } from "../utils/redcap";

function TextQuestion({ answer, handleAnswerChange }) {
  return (
    <div className="answer-container">
      <textarea
        value={answer}
        onChange={handleAnswerChange}
        className="answer"
      />
    </div>
  );
}

function CheckboxQuestion({ choices, selectedChoices, handleSelection }) {
  return (
    <div className="answer-container">
      {choices
        .filter((c) => c.value !== ANSWERED_CHOICE)
        .map((choice) => (
          <div
            key={choice.value}
            className="d-flex text-left text-md-left checkbox-container"
          >
            <input
              type="checkbox"
              id={`choice-${choice.value}`}
              className="m-3"
              checked={selectedChoices[choice.value] || false}
              onChange={handleSelection}
              name={choice.value}
            />
            <label htmlFor={`choice-${choice.value}`}>{choice.label}</label>
          </div>
        ))}
    </div>
  );
}

export default function Question({
  question,
  last,
  buttonTitle,
  buttonColor,
  updateAnswer,
  goToNextStep,
  finalizeQuestionnaire,
  record,
}) {
  const { t } = useTranslation();

  const { keycloak } = useKeycloak();

  // Text question
  let [answer, setAnswer] = useState("");

  // Checkbox question
  let [selectedChoices, setSelectedChoices] = useState({});

  // Reset answers when question changes
  useEffect(() => {
    setAnswer("");
    setSelectedChoices({});
  }, [question]);

  const saveChoices = async () => {
    for (let value in selectedChoices) {
      let answerFieldName = `${
        question.field_name
      }${CHECKBOX_ANSWER_SEPARATOR}${value.toLowerCase()}`;

      await Backend.saveAnswer(
        keycloak.token,
        record.id,
        answerFieldName,
        selectedChoices[value]
      );

      updateAnswer(answerFieldName, selectedChoices[value]);
    }

    // Save also the "hidden" ANSWERED choice to indicate that we answered this question
    let hiddenAnsweredFieldName = `${
      question.field_name
    }${CHECKBOX_ANSWER_SEPARATOR}${ANSWERED_CHOICE.toLowerCase()}`;

    await Backend.saveAnswer(
      keycloak.token,
      record.id,
      hiddenAnsweredFieldName,
      1
    );

    updateAnswer(hiddenAnsweredFieldName, 1);
  };

  const handleAnswerChange = (e) => {
    setAnswer(e.target.value);
  };

  const handleSelection = (e) => {
    setSelectedChoices((c) => ({
      ...c,
      [e.target.name]: e.target.checked ? 1 : 0,
    }));
  };

  const handleContinueClick = async () => {
    // Save the answer in the backend (depending on the type)

    if (question.choices) {
      await saveChoices(question, selectedChoices);
    } else {
      await Backend.saveAnswer(
        keycloak.token,
        record.id,
        question.field_name,
        answer
      );
    }

    if (finalizeQuestionnaire) await finalizeQuestionnaire();

    // Only go to the next step if this is the last question
    if (goToNextStep && last) goToNextStep();

    // Update the state
    updateAnswer(question.field_name, answer);
  };

  return (
    <div className="question-container">
      <h2 className="question-text">{question.field_label}</h2>
      {!question.choices ? (
        <TextQuestion answer={answer} handleAnswerChange={handleAnswerChange} />
      ) : (
        <CheckboxQuestion
          choices={question.choices}
          selectedChoices={selectedChoices}
          handleSelection={handleSelection}
        />
      )}
      <Button
        color={buttonColor ? buttonColor : "success"}
        disabled={!question.choices && answer.length === 0}
        onClick={handleContinueClick}
      >
        {buttonTitle ? buttonTitle : t("continue")}
      </Button>
    </div>
  );
}
