import React from "react";
import { Redirect, Route } from "react-router-dom";
import { KEYCLOAK_ADMIN_ROLE, useUser } from "../context/UserContext";

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { roles } = useUser();

  if (roles.includes(KEYCLOAK_ADMIN_ROLE)) {
    return (
      <Route
        {...rest}
        render={(props) => {
          return <Component {...rest} {...props} />;
        }}
      />
    );
  } else {
    return <Redirect to="/"></Redirect>;
  }
};
