import React from "react";
import { FormFeedback, FormGroup, FormText, Input, Label } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Field, useFormikContext } from "formik";

export default function MyFormGroup({
  fieldName,
  type = "text",
  text,
  label,
  placeholder,
  ...props
}) {
  const { t } = useTranslation();

  const { errors, touched } = useFormikContext();

  return (
    <FormGroup>
      {label !== false && (
        <Label htmlFor={fieldName}>{!label ? t(fieldName) : label}</Label>
      )}
      <Input
        {...props}
        tag={Field}
        type={type}
        name={fieldName}
        id={fieldName}
        placeholder={placeholder ? placeholder : t(fieldName)}
        valid={!errors[fieldName] && touched[fieldName]}
        invalid={errors[fieldName] && touched[fieldName]}
      />
      <FormFeedback>{errors[fieldName]}</FormFeedback>
      {text && <FormText color="muted">{text}</FormText>}
    </FormGroup>
  );
}
