import React, { useContext, useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { Backend } from "../services/backend";

export const UserContext = React.createContext();
export const useUser = () => useContext(UserContext);

export const KEYCLOAK_RESOURCE_ACCESS = "resource_access";
export const KEYCLOAK_FRONTEND_CLIENT_ID =
  process.env.REACT_APP_KEYCLOAK_CLIENTID;
export const KEYCLOAK_ADMIN_ROLE = "superadmin";

export default function UserProvider({ children }) {
  const { initialized, keycloak } = useKeycloak();

  const [user, setUser] = useState(null);
  const [roles, setRoles] = useState(null);

  /* Define current user based on keycloak information */
  useEffect(() => {
    async function loadUserInfo() {
      let userSub = keycloak.tokenParsed.sub;

      // Get full user from the backend
      let user = await Backend.getUserBySub(keycloak.token, userSub);

      let userRoles = Object.keys(
        keycloak.tokenParsed[KEYCLOAK_RESOURCE_ACCESS]
      ).includes(KEYCLOAK_FRONTEND_CLIENT_ID)
        ? keycloak.tokenParsed[KEYCLOAK_RESOURCE_ACCESS][
            KEYCLOAK_FRONTEND_CLIENT_ID
          ].roles
        : [];

      setUser(user);
      setRoles(userRoles);
    }
    if (initialized) {
      console.log("LOADING USER INFO & ROLES");
      loadUserInfo();
    }
  }, [initialized, keycloak]);

  const userContext = React.useMemo(
    () => ({
      user,
      setUser,
      roles,
      setRoles,
    }),
    [user, setUser, roles, setRoles]
  );

  return (
    <UserContext.Provider value={userContext}>{children}</UserContext.Provider>
  );
}
