import { saveAs } from "file-saver";

/**
 * Download a file using `filename` specified in `content-disposition` header
 * @param {Response} response - response from which to download the content
 */
export async function downloadFile(response) {
  try {
    if (!response.ok) {
      throw new Error(response);
    }

    // Extract filename from header
    const filename = response.headers
      .get("content-disposition")
      .split(";")
      .find((n) => n.includes("filename="))
      .replace("filename=", "")
      .replaceAll('"', "")
      .trim();

    const blob = await response.blob();

    // Download the file
    saveAs(blob, filename);
  } catch (error) {
    throw new Error(error);
  }
}
